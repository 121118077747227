import "./CaseOpener.css";
import CoinImage from "../../images/coin.svg";
import { useCallback, useEffect, useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useAuth } from "../../contexts/AuthContext";
const { v4: uuidv4 } = require("uuid");

const SpinnerItem = ({ item, index, highlightIndex }) => {
  var glowClass = `item-glow item-glow-${item.rarity}`;
  var highlightClass = index === highlightIndex ? "highlighted" : "";
  return (
    <div className={`spinner-item ${highlightClass}`}>
      <div className="spinner-item-image-container">
        <img className="spinner-item-image" src={item.imageUrl} alt="" />
      </div>
      <div className={glowClass} />
    </div>
  );
};

function convertDateStringToUTC(date) {
  if (!date) {
    return new Date(0); // Return epoch time if date is undefined
  }

  var t = date.replace("T", " ").split(/[- :]/);

  return new Date(Date.UTC(t[0], t[1] - 1, t[2], t[3], t[4], t[5]));
}

const CaseOpening = ({ maxQuantity, spinnerAmount, setRewards, caseInfo }) => {
  const { user, setUser } = useAuth();
  const [quantity, setQuantity] = useState(1);
  const [errors, setErrors] = useState([]);
  const [spinnerItems, setSpinnerItems] = useState([]);

  const [cooldownTimer, setCooldownTimer] = useState("");
  const isLevelTooLow = user.playerLevelInfo.level < caseInfo.levelRequired;
  const cooldownEndTime = convertDateStringToUTC(
    caseInfo.cooldownEnd
  ).getTime();
  const isOnCooldown = cooldownEndTime > Date.now();
  const centeredIndex = Math.floor(45);
  //console.log(`centeredIndex: ${centeredIndex}`);
  const [highlightIndex, setHighlightIndex] = useState(centeredIndex);

  const addError = (message) => {
    const id = Date.now(); // Unique identifier
    setErrors((prevErrors) => [...prevErrors, { id, message }]);
    setTimeout(() => {
      setErrors((prevErrors) => prevErrors.filter((error) => error.id !== id));
    }, 5000);
  };

  let buttonText = `open case`;
  if (isLevelTooLow) {
    buttonText = `LEVEL ${caseInfo.levelRequired}`;
  } else if (isOnCooldown) {
    buttonText = cooldownTimer || `Calculating...`;
  }

  useEffect(() => {
    let interval;

    if (isOnCooldown) {
      interval = setInterval(() => {
        const now = Date.now();
        const timeLeft = cooldownEndTime - now;

        if (timeLeft <= 0) {
          clearInterval(interval);
          setCooldownTimer("");
        } else {
          const hours = Math.floor(timeLeft / (1000 * 60 * 60));
          const minutes = Math.floor(
            (timeLeft % (1000 * 60 * 60)) / (1000 * 60)
          );
          const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);

          setCooldownTimer(
            `${hours > 0 ? `${hours}H ` : ""}${
              minutes > 0 ? `${minutes}M ` : ""
            }${seconds}S`
          );
        }
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [cooldownEndTime, isOnCooldown]);

  const generateSpinnerItems = useCallback(
    (count) => {
      const drops = [...caseInfo.drops]; // Clone the drops to prevent mutation
      const result = [];

      for (let i = 0; i < count; i++) {
        const rand = Math.random();
        let sum = 0;
        const newUuid = uuidv4();
        for (const drop of drops) {
          sum += drop.probability;
          if (rand <= sum) {
            const itemClone = { ...drop.item, uuid: newUuid }; // Clone item and add new uuid
            result.push(itemClone);
            break;
          }
        }
      }
      return result;
    },
    [caseInfo.drops]
  );

  useEffect(() => {
    if (caseInfo?.drops) {
      const items = generateSpinnerItems(spinnerAmount);
      setSpinnerItems(items);
    }
  }, [caseInfo, generateSpinnerItems, spinnerAmount]);

  function spin(wonItem) {
    const squareSize = 150; // Size of each square
    const bar = document.getElementById("spinner-items-container");
    bar.classList.remove("bar-animation-finished");
    let startTime;
    var position;
    const newItems = generateSpinnerItems(51);
    setSpinnerItems((prevItems) => {
      newItems[45] = wonItem;
      return newItems;
    });

    var randomOffset = Math.floor(Math.random() * 149);
    function animate(time) {
      if (!startTime) {
        startTime = time;
      }

      var totalAnimationTime = 6000; //ms
      const timeElapsed = time - startTime;
      const progress = Math.min(timeElapsed / totalAnimationTime);
      const easeInOutCubic =
        progress < 0.5
          ? 4 * progress * progress * progress
          : 1 - Math.pow(-2 * progress + 2, 3) / 2;

      position = easeInOutCubic * (2925 + randomOffset); // Valid values for offset + randomOffset: 2925 - 3074

      bar.style.transform = `translate3d(-${position}px, 0, 0)`;

      const currentIndex =
        Math.floor((position - 75) / squareSize + 26) % spinnerAmount;
      //console.log(`time: ${time}, currentIndex: ${currentIndex}, position: ${position}`)
      setHighlightIndex(currentIndex);

      if (progress < 1) {
        requestAnimationFrame(animate);
      } else {
        // Start snapping
        snapToClosestSquare();
      }
    }

    function snapToClosestSquare() {
      const remainder = position % squareSize;
      const adjustment =
        remainder > squareSize / 2 ? squareSize - remainder : -remainder;
      const snapPosition = position + adjustment;

      let snapStartTime;

      function snapAnimate(time) {
        if (!snapStartTime) {
          snapStartTime = time;
        }

        const timeElapsed = time - snapStartTime;
        const progress = Math.min(timeElapsed / 400, 1); // 0.4 seconds to complete the snap

        const easeInOutCubic =
          progress < 0.5
            ? 4 * progress * progress * progress
            : 1 - Math.pow(-2 * progress + 2, 3) / 2;

        const snap = position + (snapPosition - position) * easeInOutCubic;

        bar.style.transform = `translate3d(-${snap}px, 0, 0)`;

        if (progress < 1) {
          requestAnimationFrame(snapAnimate);
        } else {
          position = snap;
          bar.classList.add("bar-animation-finished");
        }
      }

      requestAnimationFrame(snapAnimate);
    }

    requestAnimationFrame(animate);
  }

  function demospin() {
    const squareSize = 150; // Size of each square

    const bar = document.getElementById("spinner-items-container");
    let startTime;
    var position;

    function animate(time) {
      if (!startTime) {
        startTime = time;
      }

      var totalAnimationTime = 6000; //ms
      const timeElapsed = time - startTime;
      const progress = Math.min(timeElapsed / totalAnimationTime);
      const easeInOutCubic =
        progress < 0.5
          ? 4 * progress * progress * progress
          : 1 - Math.pow(-2 * progress + 2, 3) / 2;

      position = easeInOutCubic * 3088;

      bar.style.transform = `translate3d(-${position}px, 0, 0)`;

      const currentIndex =
        Math.floor((position - 75) / squareSize + 26) % spinnerAmount;
      //console.log(`time: ${time}, currentIndex: ${currentIndex}, position: ${position}`)
      setHighlightIndex(currentIndex);

      if (progress < 1) {
        requestAnimationFrame(animate);
      } else {
        // Start snapping
        snapToClosestSquare();
      }
    }
    function snapToClosestSquare() {
      const remainder = position % squareSize;
      const adjustment =
        remainder > squareSize / 2 ? squareSize - remainder : -remainder;
      const snapPosition = position + adjustment;

      let snapStartTime;

      function snapAnimate(time) {
        if (!snapStartTime) {
          snapStartTime = time;
        }

        const timeElapsed = time - snapStartTime;
        const progress = Math.min(timeElapsed / 400, 1); // 0.4 seconds to complete the snap

        const easeInOutCubic =
          progress < 0.5
            ? 4 * progress * progress * progress
            : 1 - Math.pow(-2 * progress + 2, 3) / 2;

        const snap = position + (snapPosition - position) * easeInOutCubic;

        bar.style.transform = `translate3d(-${snap}px, 0, 0)`;

        if (progress < 1) {
          requestAnimationFrame(snapAnimate);
        } else {
          position = snap;
        }
      }

      requestAnimationFrame(snapAnimate);
    }

    requestAnimationFrame(animate);
  }

  const openBox = async () => {
    try {
      let response = await axios.post("/cases/open", {
        caseId: caseInfo.id,
        amount: quantity,
      });

      if (response?.status !== 200) {
        addError(response?.data);
        return;
      }

      setRewards(response.data);
      const newBalances = user.balances.map((balance) => {
        if (balance.currency === caseInfo.currency) {
          balance.amount -= caseInfo.cost * quantity;
        }
        return balance;
      });

      setUser((user) => ({ ...user, balances: newBalances }));
      spin(response.data[0]); //TODO: Should probably use state or something
    } catch (exception) {
      const { code, message } = exception.response?.data;
      addError(`${message} (${code})`);
    }
  };

  const openDemoBox = async () => {
    demospin();
  };

  if (
    !caseInfo?.tags?.includes("new") &&
    new Date(caseInfo.created) >=
      new Date(new Date().getTime() - 10 * 24 * 60 * 60 * 1000)
  ) {
    // If created within last 10 days, add [NEW] tag
    if (caseInfo.tags === "") caseInfo.tags += "new";
    else caseInfo.tags += ",new";
  }

  const displayTags = ["new", "hot", "partners"];
  const tagsArray = caseInfo?.tags?.split(",");
  const filteredTags = tagsArray?.filter((tag) => displayTags.includes(tag));
  return (
    <div className="case-opening-container">
      <div className="case-info-outer-container">
        <div className="case-info-container">
          <div className="case-info-image-container">
            {tagsArray?.includes("daily") ? (
              <img
                className="unboxing-case-item-image"
                src={
                  caseInfo?.drops?.length > 0
                    ? caseInfo.drops[0].item.imageUrl
                    : ""
                }
                alt=""
              />
            ) : (
              ""
            )}
            <img
              className="case-info-image"
              src={require(`../../images/cases/${caseInfo.image}`)}
              alt=""
            />
          </div>

          <div className="case-info-opening">
            <div className="case-info-title">
              {caseInfo.name}
              <div className="case-tags">
                {filteredTags?.map((tag, index) => (
                  <div key={index} className={`_${tag.trim()}`} />
                ))}
              </div>
            </div>
            <div className="case-info-case-quantity-selector">
              {[...Array(maxQuantity).keys()].map((i) => (
                <button
                  key={i}
                  className={`case-info-case-quantity-button ${
                    quantity === i + 1 ? "selected" : ""
                  }`}
                  name="case-quantity"
                  onClick={() => setQuantity(i + 1)}
                >
                  {i + 1}
                </button>
              ))}
            </div>
            <div className="case-info-buttons">
              <SpinButton
                caseInfo={caseInfo}
                openBox={openBox}
                tags={tagsArray}
                quantity={quantity}
                buttonText={buttonText}
                disabled={isLevelTooLow || isOnCooldown}
              ></SpinButton>
              <button
                className="open-case-button-demo"
                name="play-demo"
                onClick={() => openDemoBox()}
              >
                DEMO SPIN
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="roll-outer-container">
        <div className="roll-container">
          <div
            className="spinner-items-container"
            id="spinner-items-container"
            style={{ transform: "translate3d(-3000px, 0, 0)" }}
          >
            {spinnerItems.map((item, index) => (
              <SpinnerItem
                key={item.uuid}
                item={item}
                index={index}
                highlightIndex={highlightIndex}
              />
            ))}
          </div>
        </div>
      </div>
      {/* Error messages */}
      <div className="error-container">
        {errors.map((error) => (
          <div key={error.id} className="error-box">
            {error.message}
          </div>
        ))}
      </div>
    </div>
  );
};

const SpinButton = ({
  caseInfo,
  tags,
  quantity,
  openBox,
  buttonText,
  disabled,
}) => {
  if (tags?.includes("daily"))
    return (
      <button
        className="button open-case-button"
        name="play"
        onClick={() => openBox()}
        disabled={disabled}
      >
        <span id="case-open-quantity-text">{buttonText}</span>
      </button>
    );

  return (
    <button
      className="button open-case-button"
      name="play"
      onClick={() => openBox()}
    >
      <span id="case-open-quantity-text">
        {`Open ${quantity} ${quantity > 1 ? "times" : "time"}`}
      </span>
      {caseInfo.cost > 0 ? (
        <img className="open-case-button-coin-image" src={CoinImage} alt="" />
      ) : (
        ""
      )}
      {caseInfo.cost > 0 ? (
        <span id="case-cost">{(caseInfo.cost * quantity).toFixed(2)}</span>
      ) : (
        ""
      )}
    </button>
  );
};

const CaseContent = ({ caseInfo }) => {
  //console.log("drops");
  //console.log(caseInfo.drops);
  return (
    <div className="case-content-container">
      <div className="case-content-container-header">IN THIS CASE</div>
      <div className="case-content-container-items pretty-scrollbar">
        {caseInfo.drops
          ?.sort((a, b) => b.item.value - a.item.value)
          .map((drop, index) => (
            <CaseContentItem key={uuidv4()} drop={drop} />
          ))}
      </div>
    </div>
  );
};

const CaseContentItem = ({ drop }) => {
  //console.log(drop.item.rarity);
  return (
    <div
      className={`case-content-container-item item-glow-${drop.item.rarity}`}
    >
      <div className="case-content-container-item-probability">
        {drop.probability * 100}%
      </div>
      <div className="case-content-container-item-images">
        <img
          className="case-content-container-item-image"
          src={drop.item.imageUrl}
          alt=""
        />
      </div>
      <div className="case-content-container-item-category">
        {drop.item.brand}
      </div>
      <div className="case-content-container-item-name">{drop.item.name}</div>
      <div className="case-content-container-item-price">
        <img
          className="case-content-container-item-coin-image"
          src={CoinImage}
          alt=""
        />
        {drop.item.value.toFixed(2)}
      </div>
    </div>
  );
};

const Body = () => {
  const [rewards, setRewards] = useState("");
  const [caseInfo, setCaseInfo] = useState({
    name: "Loading",
    image: "case.png",
  }); // TODO: Replace with placeholder image
  const { caseId } = useParams();

  useEffect(() => {
    axios
      .get(`/cases/${caseId}`)
      .then((response) => {
        setCaseInfo(response.data);
      })
      .catch((error) => {
        console.error("Failed to fetch case info:", error);
      });
  }, [caseId]);

  return (
    <div className="csgoroll-body-container">
      <div className="middle-container">
        <CaseOpening
          maxQuantity={4}
          spinnerAmount={51}
          setRewards={setRewards}
          caseInfo={caseInfo}
        />
        <CaseContent caseInfo={caseInfo} />
      </div>
    </div>
  );
};

export const CaseOpener = () => {
  return <Body />;
};
