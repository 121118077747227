import React, { useState, useEffect } from "react";
import axios from "axios";
import "./CaseBattles.css";
import { Link, useNavigate } from "react-router-dom";
import { useSignalR } from "../../contexts/SignalRContext";
import CoinImage from "../../images/coin.svg";
import FireImage from "../../images/pvp-fire.svg";
import { AvatarDisplay } from "../../global/Global";
import { useAuth } from "../../contexts/AuthContext";
import Expire from "../../Expire";

const SharedBattleIcon = () => {
  return (
    <span>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.5 5C7.5 6.38071 8.61925 7.5 10 7.5C11.3807 7.5 12.5 6.38071 12.5 5C12.5 3.61929 11.3807 2.5 10 2.5C8.61925 2.5 7.5 3.61929 7.5 5Z"
          fill="currentColor"
        ></path>
        <path
          d="M2.08325 15C2.08325 16.3807 3.20254 17.5 4.58325 17.5C5.96396 17.5 7.08325 16.3807 7.08325 15C7.08325 13.6192 5.96396 12.5 4.58325 12.5C3.20254 12.5 2.08325 13.6192 2.08325 15Z"
          fill="currentColor"
        ></path>
        <path
          d="M15.4167 17.5C14.036 17.5 12.9167 16.3807 12.9167 15C12.9167 13.6192 14.036 12.5 15.4167 12.5C16.7975 12.5 17.9167 13.6192 17.9167 15C17.9167 16.3807 16.7975 17.5 15.4167 17.5Z"
          fill="currentColor"
        ></path>
        <path
          d="M6.00382 6.30191C6.26261 6.07349 6.28724 5.67854 6.05883 5.41974C5.83041 5.16094 5.43545 5.13632 5.17665 5.36473C3.66389 6.69991 2.70825 8.6554 2.70825 10.8333C2.70825 11.1785 2.98808 11.4583 3.33325 11.4583C3.67843 11.4583 3.95825 11.1785 3.95825 10.8333C3.95825 9.0289 4.74849 7.40988 6.00382 6.30191Z"
          fill="currentColor"
        ></path>
        <path
          d="M14.8232 5.36473C14.5645 5.13632 14.1695 5.16094 13.9411 5.41974C13.7127 5.67854 13.7373 6.07349 13.9961 6.30191C15.2514 7.40988 16.0417 9.0289 16.0417 10.8333C16.0417 11.1785 16.3215 11.4583 16.6667 11.4583C17.0118 11.4583 17.2917 11.1785 17.2917 10.8333C17.2917 8.6554 16.336 6.69991 14.8232 5.36473Z"
          fill="currentColor"
        ></path>
        <path
          d="M8.48915 16.6848C8.1549 16.5987 7.81417 16.7999 7.72813 17.1342C7.64209 17.4685 7.84334 17.8093 8.17762 17.8953C8.76082 18.0453 9.37157 18.125 10.0001 18.125C10.6286 18.125 11.2393 18.0453 11.8225 17.8953C12.1568 17.8093 12.3581 17.4685 12.272 17.1342C12.186 16.7999 11.8452 16.5987 11.511 16.6848C11.0287 16.8088 10.5226 16.875 10.0001 16.875C9.47757 16.875 8.9714 16.8088 8.48915 16.6848Z"
          fill="currentColor"
        ></path>
      </svg>
    </span>
  );
};

const PrivateBattleIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        fill="currentColor"
        d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z"
      ></path>
    </svg>
  );
};

const CrazyBattleIcon = () => {
  return (
    <span>
      <svg
        width="20"
        height="21"
        viewBox="0 0 20 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.48063 15.9932C8.89779 15.2608 12.1 15.8583 12.823 16.0124L12.8234 16.0125C12.9266 16.0319 13.0093 15.9933 13.113 15.8777L14.2493 13.8926C15.1584 12.4278 16.4393 12.7748 16.6871 12.8711C16.7283 12.8905 16.749 12.9097 16.7903 12.9097C17.7406 13.1602 17.1002 14.0661 17.1002 14.0661L17.1254 14.0871C17.0191 14.0608 16.9066 14.0466 16.7898 14.0466C16.1287 14.0466 15.5709 14.5092 15.5709 15.1067C15.5709 15.7042 16.1287 16.1667 16.7898 16.1667C17.4509 16.1667 18.0087 15.6849 18.0087 15.1067C18.0087 14.8805 17.9265 14.669 17.7856 14.4953C19.6929 12.8601 17.9438 11.1725 17.7199 10.9825L17.6579 10.9247C15.4061 8.66967 11.9145 11.1367 11.9145 11.1367C11.5014 10.385 11.3568 9.884 11.3154 9.71049C11.305 9.68146 11.2998 9.6573 11.2946 9.63313C11.2895 9.60915 11.2843 9.58515 11.2741 9.55634C10.9434 8.49629 11.0674 7.78316 11.2947 7.32058C11.5427 6.80017 12.1417 6.47251 12.7822 6.54959C13.2496 6.60774 13.4468 6.85224 13.5155 6.96892C13.0349 7.10682 12.6786 7.49817 12.6786 7.97569C12.6786 8.57316 13.2363 9.05497 13.8975 9.03573C14.5586 9.03573 15.1164 8.57316 15.1164 7.97569C15.1164 7.53227 14.8006 7.14555 14.3458 6.98933C14.1538 5.84462 12.7934 5.39128 12.0177 5.23898C11.6457 5.16179 11.2739 5.14255 10.9021 5.20039C7.28658 5.68204 7.47256 10.616 7.47256 10.616C5.55108 9.36321 4.0842 9.4403 3.05128 9.88362C1.00594 10.7704 0.737303 13.2373 2.51407 14.4707L2.52818 14.4796C2.44018 14.6256 2.39005 14.7926 2.39005 14.9716C2.39005 15.5691 2.94788 16.0317 3.60895 16.0317C4.27002 16.0317 4.82786 15.5691 4.82786 14.9716C4.82786 14.3934 4.27002 13.9116 3.60895 13.9116C3.48674 13.9116 3.36804 13.9274 3.25575 13.957C3.12135 13.8458 3.06315 13.6547 3.03053 13.4877C2.98916 13.2949 3.00991 13.083 3.11315 12.8902C3.50561 12.1193 4.6419 12.5626 4.6419 12.5626C5.88156 12.8709 6.21203 15.203 6.27402 15.8775C6.27402 15.9546 6.37739 16.0318 6.48063 15.9932Z"
          fill="#D074FD"
        ></path>
      </svg>
    </span>
  );
};

const CaseBattle = ({ battleInfo }) => {
  const { user, setUser } = useAuth();

  const joinBattle = async () => {
    try {
      const usedSlots = new Set(
        battleInfo.players.map((player) => player.slot)
      );

      // Start checking from slot 1 upwards
      let slot = null;

      for (let i = 1; i < battleInfo.minPlayers; i++) {
        if (!usedSlots.has(i)) {
          slot = i;
          break;
        }
      }
      if (slot == null) {
        console.log("All slots full"); //TODO: Error print
        return;
      }

      let response = await axios.post("/battles/join", {
        battleId: battleInfo.id,
        team: slot === 1 || slot === 2 ? 1 : 2, // TODO: Add team selection
        slot: slot,
      });
      //if (response?.status !== 200) {
      //    addError(response?.data);
      //    return;
      //}

      // TODO: Update user balance in the UI
      // const newBalances = user.balances.map((balance) => {
      //    if (balance.currency === caseInfo.currency) {
      //        balance.amount -= caseInfo.cost * quantity;
      //    }
      //    return balance;
      // });

      //setUser((user) => ({ ...user, balances: newBalances }));
      //spin(response.data[0]); //TODO: Should probably use state or something
    } catch (exception) {
      console.log(exception);
      //const { code, message } = exception.response?.data;
      //addError(`${message} (${code})`);
    }
  };

  return (
    <Link to={`/case-battle/${battleInfo.id.value}`}>
      <div className="case-battle-display">
        <div className="case-battle-display-inner">
          <span className="case-battle-display-icons">
            {battleInfo.mode === "NORMAL" ? <SharedBattleIcon /> : ""}
            {battleInfo.mode === "NORMAL" ? <CrazyBattleIcon /> : ""}
            {battleInfo.private === true ? <PrivateBattleIcon /> : ""}
          </span>
          <div className="case-battle-display-players">
            <div className="case-battle-display-players-inner">
              {
                <AvatarDisplay
                  imageUrl={
                    battleInfo.players.find((player) => player.slot === 1)
                      ?.imageUrl
                  }
                  level={
                    battleInfo.players.find((player) => player.slot === 1)
                      ?.level
                  }
                ></AvatarDisplay>
              }
              {
                <AvatarDisplay
                  imageUrl={
                    battleInfo.players.find((player) => player.slot === 2)
                      ?.imageUrl
                  }
                  level={
                    battleInfo.players.find((player) => player.slot === 2)
                      ?.level
                  }
                ></AvatarDisplay>
              }
              <cw-icon>
                <svg
                  viewBox="0 0 22 22"
                  xmlns="http://www.w3.org/2000/svg"
                  color="#a6a7aa"
                >
                  <path
                    fill="currentColor"
                    d="M11.241 6.476l3.014 3.254 5.118-4.743.161-3.665-3.666.161-4.627 4.993zm-3.935 4.247l-1.948 2.102-1.499-1.5-.929.93 2.086 2.086-3.024 3.025 1.497 1.498 3.025-3.025 2.088 2.086.928-.93-1.5-1.497 2.214-2.051-2.938-2.724zm11.29 1.533l-.931-.928-1.498 1.5L5.657 1.482 1.99 1.324l.162 3.665 11.342 10.51-1.5 1.5.928.927 2.088-2.086 3.024 3.026 1.5-1.5-3.025-3.024 2.087-2.086z"
                  ></path>
                </svg>
              </cw-icon>
              {
                <AvatarDisplay
                  imageUrl={
                    battleInfo.players.find((player) => player.slot === 3)
                      ?.imageUrl
                  }
                  level={
                    battleInfo.players.find((player) => player.slot === 3)
                      ?.level
                  }
                ></AvatarDisplay>
              }
              {
                <AvatarDisplay
                  imageUrl={
                    battleInfo.players.find((player) => player.slot === 4)
                      ?.imageUrl
                  }
                  level={
                    battleInfo.players.find((player) => player.slot === 4)
                      ?.level
                  }
                ></AvatarDisplay>
              }
            </div>
          </div>
          <div className="case-battle-display-cases-container">
            <div className="case-battle-display-cases">
              <div className="case-battle-display-cases-inner">
                {battleInfo.cases.map((caseItem) => (
                  <div className="case-battle-display-case-container">
                    <img
                      alt=""
                      src={require(`../../images/cases/${caseItem.image}`)}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="case-battle-display-right-panel">
            {battleInfo.players.find(
              (player) => player.userId.value === user?.user.userId.value
            ) == null ? (
              <button
                className="button open-case-button"
                name="play"
                onClick={(event) => {
                  event.preventDefault(); // Prevent navigation

                  joinBattle();
                }}
              >
                <span id="case-open-quantity-text">{`Join for`}</span>
                <img
                  className="open-case-button-coin-image"
                  src={CoinImage}
                  alt=""
                />
                <span id="battle-cost">{battleInfo.cost.toFixed(2)}</span>
              </button>
            ) : (
              <div className="case-battle-go-to-battle-container">
                <button className="button go-to-battle-button">
                  <cw-icon>
                    <svg
                      viewBox="0 0 22 22"
                      xmlns="http://www.w3.org/2000/svg"
                      color="#a6a7aa"
                    >
                      <path
                        fill="currentColor"
                        d="M11.241 6.476l3.014 3.254 5.118-4.743.161-3.665-3.666.161-4.627 4.993zm-3.935 4.247l-1.948 2.102-1.499-1.5-.929.93 2.086 2.086-3.024 3.025 1.497 1.498 3.025-3.025 2.088 2.086.928-.93-1.5-1.497 2.214-2.051-2.938-2.724zm11.29 1.533l-.931-.928-1.498 1.5L5.657 1.482 1.99 1.324l.162 3.665 11.342 10.51-1.5 1.5.928.927 2.088-2.086 3.024 3.026 1.5-1.5-3.025-3.024 2.087-2.086z"
                      ></path>
                    </svg>
                  </cw-icon>
                  {`Go To Battle`}
                </button>
                <div className="case-battle-go-to-battle-unboxed">
                  <img src={FireImage} alt="" />
                  {"UNBOXED:"}
                  <img
                    className="open-case-button-coin-image"
                    src={CoinImage}
                    alt=""
                  />
                  <span className="battle-total-unboxed-value">
                    {(battleInfo.totalUnboxedValue ?? 0).toFixed(2)}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Link>
  );
};

const Body = () => {
  const signalRConnection = useSignalR();
  const [activeBattles, setActiveBattles] = useState([]);
  const [debugMessages, setDebugMessages] = useState([]);

  useEffect(() => {
    if (signalRConnection) {
      signalRConnection.on("CreateBattle", handleBattle);
      signalRConnection.on("DebugMessage", debugMessage);
    }

    return () => {
      if (signalRConnection) {
        signalRConnection.off("CreateBattle", handleBattle);
        signalRConnection.off("DebugMessage", debugMessage);
      }
    };
  }, [signalRConnection]);

  useEffect(() => {
    axios
      .get("/battles")
      .then((response) => {
        setActiveBattles(response.data);
      })
      .catch((error) => {
        console.error("Failed to fetch battles:", error);
      });
  }, []);

  const handleBattle = (battle) => {
    setActiveBattles((current) => [...current, battle]);
  };

  const debugMessage = (message) => {
    setDebugMessages((prevDebugMessages) => [
      ...prevDebugMessages,
      { message },
    ]);
  };

  return (
    <div className="body-container">
      <div className="middle-container">
        <div className="case-battles-container">
          <div className="case-battles-filters-container">
            <Link to="/create-case-battle">
              <button className="button create-battle-button">
                Create Duel
              </button>
            </Link>
          </div>
          <div className="unboxing-cases-grid">
            {activeBattles.map((battleItem) => (
              <CaseBattle battleInfo={battleItem} key={battleItem.id} />
            ))}
          </div>
        </div>
      </div>

      {/* Error messages */}
      <div className="error-container">
        {debugMessages.map((error) => (
          <Expire delay="4000">
            <div className="debug-box">{error.message}</div>
          </Expire>
        ))}
      </div>
    </div>
  );
};

export const CaseBattlesPage = () => {
  return <Body />;
};
