import "./EmpireDicePage.css";
import CoinImage from "../../images/coin.svg";
import { useCallback, useEffect, useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { getEmpireItemGlowClass } from "../../global/Global";
import { useAuth } from "../../contexts/AuthContext";
import { Link } from "react-router-dom";
import spinEndSound from "../../sounds/spin_end.mp3";
const { v4: uuidv4 } = require("uuid");

const Body = () => {


    return (
        <div className="empire-body-container">
            <div className="empire-dice">
            </div>
        </div>
    );
};

export const EmpireDicePage = () => {
    return <Body />;
};
