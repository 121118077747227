import "./EmpireCaseOpener.css";
import CoinImage from "../../images/coin.svg";
import { useCallback, useEffect, useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { getEmpireItemGlowClass } from "../../global/Global";
import { useAuth } from "../../contexts/AuthContext";
import { Link } from "react-router-dom";
import spinEndSound from "../../sounds/spin_end.mp3";
const { v4: uuidv4 } = require("uuid");

const SpinnerContainer = ({ spinnerItems, highlightIndex, isVertical }) => {
    return (
        <div className={`empire-case-opening-spinner ${isVertical ? 'vertical-spinner' : ''}`}>
            {spinnerItems.map((item, index) => (
                <SpinnerItem
                    key={item.uuid}
                    item={item}
                    index={index}
                    highlightIndex={highlightIndex}
                />
            ))}
        </div>
    );
};

const SpinnerItem = ({ item, index, highlightIndex }) => {
  var highlightClass = index === highlightIndex ? "empire-highlighted" : "";
  return (
    <div
      className={`empire-case-opening-spinner-item-container ${highlightClass}`}
    >
      <div className="empire-case-opening-spinner-item-container-inner">
        <img
          className="empire-case-opening-spinner-item-image"
          src={item.imageUrl}
          alt=""
        />
        <div
          className={`empire-case-opening-spinner-item-glow ${getEmpireItemGlowClass(
            item.value
          )}`}
        ></div>
      </div>
      <div className="empire-case-opening-spinner-item-details">
        <div className="empire-case-opening-spinner-item-details-brand">
          {item.brand}
        </div>
        <div className="empire-case-opening-spinner-item-details-name">
          {item.name}
        </div>
        <div className="empire-case-opening-spinner-item-details-price">
          <svg
            data-v-5aeb787d=""
            viewBox="0 0 22 22"
            class="w-14 h-14 fill-current text-yellow-2 mr-md my-auto"
          >
            <path d="M21.72 4a.86.86 0 0 0 0-.17c0-1.7-4.44-3.09-9.93-3.09S1.9 2.14 1.9 3.84a1.46 1.46 0 0 0 0 .22.86.86 0 0 1-.04-.06v2.55c0 .55.61 1.15 1.68 1.7 0 0 2.63 1.58 8.26 1.64a21.28 21.28 0 0 0 7-1.09c1.82-.64 2.91-1.46 2.91-2.19V4.05a.28.28 0 0 0 0-.09.08.08 0 0 1 .01.04ZM4.37 3.31c.73-1 3.78-1.71 7.43-1.71s6.78.75 7.46 1.74a.72.72 0 0 1 .14.41s0 .38 0 .41a2.55 2.55 0 0 0-.4-.32c-1.07-.76-3.9-1.2-7.18-1.2s-6 .4-7.1 1.14a1.73 1.73 0 0 0-.49.38v-.41a.77.77 0 0 1 .14-.44Zm15.52 6.46v2.55c0 .73-1.08 1.55-2.91 2.2a21 21 0 0 1-7 1.08c-5.67-.06-8.3-1.6-8.3-1.6C.61 13.42 0 12.82 0 12.27v-2.5a.28.28 0 0 1 0-.09.76.76 0 0 1 0-.16C0 9 .57 8.38 1.55 7.9V8c0 .58.67 1.21 1.85 1.78 0 0 2.58 1.38 8.1 1.55a19.56 19.56 0 0 0 7-1.08 10.48 10.48 0 0 0 1.32-.56 1 1 0 0 1-.08.28 1.59 1.59 0 0 0 .12-.27ZM22 15.41v2.5c0 .55-.61 1.15-1.68 1.7 0 0-2.63 1.57-8.27 1.63a21 21 0 0 1-7-1.08C3.19 19.52 2.11 18.7 2.11 18v-2.68a1.89 1.89 0 0 0 .09.21 10.35 10.35 0 0 0 1.23.52 19.78 19.78 0 0 0 7 1.08c5.52-.18 8.1-1.55 8.1-1.55 1.17-.57 1.85-1.2 1.85-1.78v-.08c1 .48 1.54 1 1.54 1.65v-.06a.28.28 0 0 1 .08.1Z"></path>
          </svg>
          <span>
            {item.value?.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </span>
        </div>
      </div>
    </div>
  );
};

function convertDateStringToUTC(date) {
  if (!date) {
    return new Date(0); // Return epoch time if date is undefined
  }

  var t = date.replace("T", " ").split(/[- :]/);

  return new Date(Date.UTC(t[0], t[1] - 1, t[2], t[3], t[4], t[5]));
}

const CaseOpening = ({ maxQuantity, spinnerAmount, setRewards, caseInfo }) => {
  const { user, setUser } = useAuth();
  const [quantity, setQuantity] = useState(1);
  const [errors, setErrors] = useState([]);
  const [spinnerItems, setSpinnerItems] = useState([]);
  const [isSpinning, setIsSpinning] = useState(false);
  const [fastSpin, setfastSpin] = useState(false);
  const [soundOn, setSoundOn] = useState(false);

  const cooldownEndTime = convertDateStringToUTC(
    caseInfo.cooldownEnd
  ).getTime();
  const isOnCooldown = cooldownEndTime > Date.now();
  const centeredIndex = Math.floor(75);
  //console.log(`centeredIndex: ${centeredIndex}`);
  const [highlightIndex, setHighlightIndex] = useState(centeredIndex);

  const addError = (message) => {
    const id = Date.now(); // Unique identifier
    setErrors((prevErrors) => [...prevErrors, { id, message }]);
    setTimeout(() => {
      setErrors((prevErrors) => prevErrors.filter((error) => error.id !== id));
    }, 5000);
  };

  const isVertical = quantity > 1;

    const generateSpinnerItems = useCallback(
        (count) => {
            // Create an array for each spinner
            return Array.from({ length: spinnerAmount }, () => {
                const items = [];

                for (let i = 0; i < count; i++) {
                    const rand = Math.random();
                    let sum = 0;
                    for (const drop of caseInfo.drops) {
                        sum += drop.probability;
                        if (rand <= sum) {
                            // Clone item and add a unique UUID
                            const itemClone = { ...drop.item, uuid: uuidv4() };
                            items.push(itemClone);
                            break;
                        }
                    }
                }

                return items;
            });
        },
        [caseInfo.drops, spinnerAmount]
    );

  useEffect(() => {
    if (caseInfo?.drops) {
      const items = generateSpinnerItems(spinnerAmount);
      setSpinnerItems(items);
    }
  }, [caseInfo, generateSpinnerItems, spinnerAmount]);

  function spin(wonItem, spinnerIndex, callback) {
    const squareSize = 120; // Size of each square
    const bar = document.getElementById(`spinner-items-container-${spinnerIndex}`);
    bar.classList.remove("empire-bar-animation-finished");
    let startTime;
    var position;
      

      var randomOffset = Math.floor(Math.random() * 119);

    function animate(time) {
      if (!startTime) {
        startTime = time;
      }

      var totalAnimationTime = fastSpin ? 1500 : 6000; //ms
      const timeElapsed = time - startTime;
      const progress = Math.min(timeElapsed / totalAnimationTime);
      const easeInOutCubic =
        progress < 0.5
          ? 4 * progress * progress * progress
          : 1 - Math.pow(-2 * progress + 2, 3) / 2;

      position = easeInOutCubic * (4740 + randomOffset); // Valid values for offset + randomOffset: 2925 - 3074

      if(isVertical)
            bar.style.transform = `translate3d(0, -${position}px, 0)`;
      else
        bar.style.transform = `translate3d(-${position}px, 0, 0)`;

      const currentIndex =
        Math.floor((position - 60) / squareSize + 51) % spinnerAmount;
      setHighlightIndex(currentIndex);

      if (progress < 1) {
        requestAnimationFrame(animate);
      } else {
        // Start snapping
        snapToClosestSquare();
      }
    }

    function snapToClosestSquare() {
      if (soundOn) {
        const audio = new Audio(spinEndSound);
        audio.play();
      }

      const remainder = position % squareSize;
      const adjustment =
        remainder > squareSize / 2 ? squareSize - remainder : -remainder;
      const snapPosition = position + adjustment;

      let snapStartTime;

      function snapAnimate(time) {
        if (!snapStartTime) {
          snapStartTime = time;
        }

        const timeElapsed = time - snapStartTime;
        const progress = Math.min(timeElapsed / (fastSpin ? 200 : 400), 1); // 0.4 seconds to complete the snap

        const easeInOutCubic =
          progress < 0.5
            ? 4 * progress * progress * progress
            : 1 - Math.pow(-2 * progress + 2, 3) / 2;

        const snap = position + (snapPosition - position) * easeInOutCubic;

          if (isVertical)
              bar.style.transform = `translate3d(0, -${snap}px, 0)`;
          else
              bar.style.transform = `translate3d(-${snap}px, 0, 0)`;

        if (progress < 1) {
          requestAnimationFrame(snapAnimate);
        } else {
          position = snap;
          bar.classList.add("empire-bar-animation-finished");
          if (callback) callback(); // Call the callback at the end of the animation
        }
      }

      requestAnimationFrame(snapAnimate);
    }

    requestAnimationFrame(animate);
  }

  function demospin(spinnerIndex, callback) {
    setIsSpinning(true);
    const squareSize = 120; // Size of each square

      const bar = document.getElementById(`spinner-items-container-${spinnerIndex}`);
    bar.classList.remove("empire-bar-animation-finished");
    let startTime;
    var position;

    const newItems = generateSpinnerItems(101);
    setSpinnerItems(newItems);

    var randomOffset = Math.floor(Math.random() * 119);
    function animate(time) {
      if (!startTime) {
        startTime = time;
      }

      var totalAnimationTime = fastSpin ? 1500 : 6000; //ms
      const timeElapsed = time - startTime;
      const progress = Math.min(timeElapsed / totalAnimationTime);
      const easeInOutCubic =
        progress < 0.5
          ? 4 * progress * progress * progress
          : 1 - Math.pow(-2 * progress + 2, 3) / 2;
        console.log("EA");
      position = easeInOutCubic * (4740 + randomOffset);
        console.log(position);

        if (isVertical)
            bar.style.transform = `translate3d(0, -${position}px, 0)`;
        else
            bar.style.transform = `translate3d(-${position}px, 0, 0)`;

      const currentIndex =
        Math.floor((position - 60) / squareSize + 51) % spinnerAmount;
      setHighlightIndex(currentIndex);

      if (progress < 1) {
        requestAnimationFrame(animate);
      } else {
        // Start snapping
        snapToClosestSquare();
      }
    }

    function snapToClosestSquare() {
      if (soundOn) {
        const audio = new Audio(spinEndSound);
        audio.play();
      }

      const remainder = position % squareSize;
      const adjustment =
        remainder > squareSize / 2 ? squareSize - remainder : -remainder;
      const snapPosition = position + adjustment;

      let snapStartTime;

      function snapAnimate(time) {
        if (!snapStartTime) {
          snapStartTime = time;
        }

        const timeElapsed = time - snapStartTime;
        const progress = Math.min(timeElapsed / (fastSpin ? 200 : 400), 1); // 0.4 seconds to complete the snap

        const easeInOutCubic =
          progress < 0.5
            ? 4 * progress * progress * progress
            : 1 - Math.pow(-2 * progress + 2, 3) / 2;

        const snap = position + (snapPosition - position) * easeInOutCubic;

          if (isVertical)
              bar.style.transform = `translate3d(0, -${snap}px, 0)`;
          else
              bar.style.transform = `translate3d(-${snap}px, 0, 0)`;

        if (progress < 1) {
          requestAnimationFrame(snapAnimate);
        } else {
          position = snap;
          bar.classList.add("empire-bar-animation-finished");
          if (callback) callback();
        }
      }

      requestAnimationFrame(snapAnimate);
    }

    requestAnimationFrame(animate);
  }

  const openBox = async () => {
    setIsSpinning(true);
    try {
      let response = await axios.post("/cases/open", {
        caseId: caseInfo.id,
        amount: quantity,
      });

      if (response?.status !== 200) {
        addError(response?.data);
        return;
      }

      setRewards(response.data);
      let newBalances = user.balances.map((balance) => {
        if (balance.currency === caseInfo.currency) {
          balance.amount -= caseInfo.cost * quantity;
        }
        return balance;
      });

      setUser((user) => ({ ...user, balances: newBalances }));

        var newItems = generateSpinnerItems(spinnerAmount);


        response.data.forEach((item, index) => {

            newItems[index][90] = item;

        });

        setSpinnerItems(newItems);

        response.data.forEach((item, index) => {
            if (index < quantity) {
                spin(item, index, () => {
                    if (index === quantity - 1) {
                        setIsSpinning(false)
                        setUser((currentUser) => {
                            const newBalanceWithWin = currentUser.balances.map((balance) => {
                                if (balance.currency === response.data[0].currency) {
                                    const totalWinValue = response.data.reduce((sum, item) => sum + item.value, 0);
                                    balance.amount += totalWinValue;
                                }
                                return balance;
                            });
                            return { ...currentUser, balances: newBalanceWithWin };
                        });
                    }
                });
            }
        });
    } catch (exception) {
      const { code, message } = exception.response?.data;
      addError(`${message} (${code})`);
      setIsSpinning(false);
    }
  };

  const openDemoBox = async () => {
      setIsSpinning(true);
      for (let i = 0; i < quantity; i++) {
          demospin(i, () => {
              setIsSpinning(false); // Set to false when animation completes
          });
      }
    
    };

    const setSpinnerQuantity = async (quantity) => {
        setQuantity(quantity);
        for (let i = 0; i < quantity; i++) {
            const bar = document.getElementById(`spinner-items-container-${i}`);
            bar.style.transform = `translate3d(0, 0, 0)`;
        }
        
    };

  return (
    <div className="empire-case-opening-container">
      <div className="empire-case-opening-back-header">
        <Link to="/empire-unboxing">
          <div>
            <svg
              data-v-36d539dd=""
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              fill="currentColor"
              class="fill-current"
            >
              <path d="M4.652 6.255H14v1.871H4.652v1.865L0 7.19l4.652-3.182v2.246Z"></path>
            </svg>
          </div>
          <span>Back to Cases</span>
        </Link>
      </div>
      <div className="empire-case-opening-case-info-header">
        <div className="empire-case-opening-case-info-header-left">
          <img
            src={require(`../../images/empire/cases/${caseInfo.image}`)}
            alt=""
          ></img>
          <div>
            <span className="empire-case-opening-case-info-header-left-name">
              {caseInfo.name}
            </span>
            <span className="empire-case-opening-case-info-header-left-price-container">
              <svg
                data-v-5aeb787d=""
                viewBox="0 0 22 22"
                class="w-14 h-14 fill-current text-yellow-2 mr-md my-auto"
              >
                <path d="M21.72 4a.86.86 0 0 0 0-.17c0-1.7-4.44-3.09-9.93-3.09S1.9 2.14 1.9 3.84a1.46 1.46 0 0 0 0 .22.86.86 0 0 1-.04-.06v2.55c0 .55.61 1.15 1.68 1.7 0 0 2.63 1.58 8.26 1.64a21.28 21.28 0 0 0 7-1.09c1.82-.64 2.91-1.46 2.91-2.19V4.05a.28.28 0 0 0 0-.09.08.08 0 0 1 .01.04ZM4.37 3.31c.73-1 3.78-1.71 7.43-1.71s6.78.75 7.46 1.74a.72.72 0 0 1 .14.41s0 .38 0 .41a2.55 2.55 0 0 0-.4-.32c-1.07-.76-3.9-1.2-7.18-1.2s-6 .4-7.1 1.14a1.73 1.73 0 0 0-.49.38v-.41a.77.77 0 0 1 .14-.44Zm15.52 6.46v2.55c0 .73-1.08 1.55-2.91 2.2a21 21 0 0 1-7 1.08c-5.67-.06-8.3-1.6-8.3-1.6C.61 13.42 0 12.82 0 12.27v-2.5a.28.28 0 0 1 0-.09.76.76 0 0 1 0-.16C0 9 .57 8.38 1.55 7.9V8c0 .58.67 1.21 1.85 1.78 0 0 2.58 1.38 8.1 1.55a19.56 19.56 0 0 0 7-1.08 10.48 10.48 0 0 0 1.32-.56 1 1 0 0 1-.08.28 1.59 1.59 0 0 0 .12-.27ZM22 15.41v2.5c0 .55-.61 1.15-1.68 1.7 0 0-2.63 1.57-8.27 1.63a21 21 0 0 1-7-1.08C3.19 19.52 2.11 18.7 2.11 18v-2.68a1.89 1.89 0 0 0 .09.21 10.35 10.35 0 0 0 1.23.52 19.78 19.78 0 0 0 7 1.08c5.52-.18 8.1-1.55 8.1-1.55 1.17-.57 1.85-1.2 1.85-1.78v-.08c1 .48 1.54 1 1.54 1.65v-.06a.28.28 0 0 1 .08.1Z"></path>
              </svg>
              <span>
                {caseInfo.cost?.toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </span>
            </span>
          </div>
        </div>
        <div className="empire-case-opening-case-info-header-right">
          <div
            className="empire-case-opening-case-info-header-right-btn"
            onClick={() => alert("LMAO You thought! SIKE")}
          >
            <svg
              data-v-36d539dd=""
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="15"
              fill="none"
              class="fill-current"
            >
              <path
                fill="currentColor"
                d="M12.983 3.002a.544.544 0 0 0-.108-.301.418.418 0 0 0-.245-.16C10.31 2.059 9.342 1.708 7.177.57a.379.379 0 0 0-.354 0C4.658 1.707 3.69 2.058 1.37 2.542a.418.418 0 0 0-.245.159.544.544 0 0 0-.108.3c-.103 1.908.117 3.685.656 5.282a11.265 11.265 0 0 0 1.921 3.511c1.434 1.77 2.957 2.54 3.247 2.675a.373.373 0 0 0 .322 0c.29-.136 1.813-.905 3.247-2.675a11.27 11.27 0 0 0 1.918-3.51c.538-1.598.758-3.375.655-5.282Zm-2.931 2.987L6.498 9.34a.444.444 0 0 1-.138.123.382.382 0 0 1-.168.05h-.018a.4.4 0 0 1-.3-.143l-1.32-1.504a.51.51 0 0 1-.094-.161.57.57 0 0 1 .088-.545.402.402 0 0 1 .302-.15.4.4 0 0 1 .305.142l.992 1.133 3.256-2.95c.075-.1.18-.161.294-.17a.392.392 0 0 1 .312.12c.086.087.139.21.147.341a.554.554 0 0 1-.104.363Z"
              ></path>
            </svg>
            <div>Provably Fair</div>
          </div>
          <div
            className="empire-case-opening-case-info-header-right-btn"
            onClick={() => setSoundOn(!soundOn)}
          >
            {soundOn ? (
              <svg
                data-v-36d539dd=""
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                fill="currentColor"
                class="fill-current"
              >
                <g clip-path="url(#icon-sound_svg__a)">
                  <path d="M8.658 12.07c-.375.12-.658.452-.658.845 0 .498.443.883.922.747a7.003 7.003 0 0 0 0-13.466C8.443.06 8 .446 8 .943c0 .393.283.725.658.845a5.402 5.402 0 0 1 0 10.282Z"></path>
                  <path d="M9.04 3.488C8.548 3.195 8 3.613 8 4.185c0 .342.209.642.479.854.56.439.921 1.122.921 1.89 0 .768-.36 1.451-.921 1.89-.27.212-.479.512-.479.854 0 .572.548.99 1.04.698A3.998 3.998 0 0 0 11 6.929a3.998 3.998 0 0 0-1.96-3.441ZM1 4.5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h1.124a1 1 0 0 1 .659.247l2.888 2.527a.5.5 0 0 0 .829-.376V2.102a.5.5 0 0 0-.83-.376L2.784 4.253a1 1 0 0 1-.659.247H1Z"></path>
                </g>
                <defs>
                  <clipPath id="icon-sound_svg__a">
                    <path d="M0 0h14v14H0z"></path>
                  </clipPath>
                </defs>
              </svg>
            ) : (
              <svg
                data-v-36d539dd=""
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                fill="currentColor"
                class="fill-current"
              >
                <g clip-path="url(#icon-sound-off_svg__a)">
                  <path d="M8.658 12.07c-.375.12-.658.452-.658.845 0 .498.443.883.922.747a6.98 6.98 0 0 0 2.39-1.218l.122.122a.8.8 0 0 0 1.132-1.132l-10-10a.8.8 0 0 0-1.132 1.132l1.53 1.529-.181.158a1 1 0 0 1-.659.247H1a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h1.124a1 1 0 0 1 .659.247l2.888 2.527a.5.5 0 0 0 .829-.376V7.631l1.612 1.612a.91.91 0 0 0-.112.43c0 .572.548.99 1.04.698.041-.025.082-.05.123-.077l1.007 1.007c-.456.331-.965.593-1.512.769ZM14 6.93c0 1.109-.258 2.158-.717 3.09l-1.217-1.217a5.402 5.402 0 0 0-3.408-7.015C8.283 1.668 8 1.336 8 .943c0-.498.443-.883.922-.747A7.003 7.003 0 0 1 14 6.93Z"></path>
                  <path d="M11 6.93c0 .252-.024.5-.068.739L9.174 5.911a2.41 2.41 0 0 0-.695-.872C8.209 4.827 8 4.527 8 4.185c0-.572.548-.99 1.04-.697A3.998 3.998 0 0 1 11 6.929ZM6.5 2.102v1.135L5.307 2.044l.364-.318a.5.5 0 0 1 .829.376Z"></path>
                </g>
                <defs>
                  <clipPath id="icon-sound-off_svg__a">
                    <path d="M0 0h14v14H0z"></path>
                  </clipPath>
                </defs>
              </svg>
            )}
            <div>{soundOn ? "Sound on" : "Sound off"}</div>
          </div>
        </div>
      </div>
      <div>
        <div style={{ position: "relative" }}>
                  <svg class="empire-case-opening-roll-container-arrows-left" style={{ display: quantity !== 1 ? 'flex' : 'none' }} xmlns="http://www.w3.org/2000/svg" width="180" height="16" fill="currentColor"><path stroke="url(#spinner-tick-small_svg__a)" d="M0 6.5h180" opacity=".8"></path><path fill="#1A1C24" d="m90 16 9-10H81l9 10Z"></path><g filter="url(#spinner-tick-small_svg__b)"><path d="m90 10 5-6H85l5 6Z"></path></g><defs><linearGradient id="spinner-tick-small_svg__a" x1="43.65" x2="137.812" y1="7.534" y2="7.534" gradientUnits="userSpaceOnUse"><stop stop-color="currentColor" stopOpacity="0"></stop><stop offset=".49" stop-color="currentColor"></stop><stop offset="1" stop-color="currentColor" stopOpacity="0"></stop></linearGradient><filter id="spinner-tick-small_svg__b" width="18" height="14" x="81" y="0" colorInterpolationFilters="sRGB" filterUnits="userSpaceOnUse"><feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood><feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"></feColorMatrix><feOffset></feOffset><feGaussianBlur stdDeviation="2"></feGaussianBlur><feComposite in2="hardAlpha" operator="out"></feComposite><feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.4 0"></feColorMatrix><feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1504_14299"></feBlend><feBlend in="SourceGraphic" in2="effect1_dropShadow_1504_14299" result="shape"></feBlend></filter></defs></svg>
                  <svg class="empire-case-opening-roll-container-arrows-right" style={{ display: quantity !== 1 ? 'flex' : 'none' }} xmlns="http://www.w3.org/2000/svg" width="180" height="16" fill="currentColor"><path stroke="url(#spinner-tick-small_svg__a)" d="M0 6.5h180" opacity=".8"></path><path fill="#1A1C24" d="m90 16 9-10H81l9 10Z"></path><g filter="url(#spinner-tick-small_svg__b)"><path d="m90 10 5-6H85l5 6Z"></path></g><defs><linearGradient id="spinner-tick-small_svg__a" x1="43.65" x2="137.812" y1="7.534" y2="7.534" gradientUnits="userSpaceOnUse"><stop stop-color="currentColor" stopOpacity="0"></stop><stop offset=".49" stop-color="currentColor"></stop><stop offset="1" stop-color="currentColor" stopOpacity="0"></stop></linearGradient><filter id="spinner-tick-small_svg__b" width="18" height="14" x="81" y="0" colorInterpolationFilters="sRGB" filterUnits="userSpaceOnUse"><feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood><feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"></feColorMatrix><feOffset></feOffset><feGaussianBlur stdDeviation="2"></feGaussianBlur><feComposite in2="hardAlpha" operator="out"></feComposite><feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.4 0"></feColorMatrix><feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1504_14299"></feBlend><feBlend in="SourceGraphic" in2="effect1_dropShadow_1504_14299" result="shape"></feBlend></filter></defs></svg>
        <svg 
            className="empire-case-opening-roll-container-arrow"
            xmlns="http://www.w3.org/2000/svg"
            width="422"
            height="16"
            fill="none"
            style={{ display: quantity === 1 ? 'flex' : 'none' }}>
            <path
              stroke="url(#spinner-tick-large_svg__a)"
              d="M0 6.5h422"
              opacity=".8"
            ></path>
            <path fill="#1A1C24" d="m211 16 9-10h-18l9 10Z"></path>
            <g filter="url(#spinner-tick-large_svg__b)">
              <path fill="#E9B10E" d="m211 10 5-6h-10l5 6Z"></path>
            </g>
            <defs>
              <linearGradient
                id="spinner-tick-large_svg__a"
                x1="102.335"
                x2="323.094"
                y1="7.534"
                y2="7.534"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#E9B10E" stopOpacity="0"></stop>
                <stop offset=".49" stop-color="#E9B10E"></stop>
                <stop offset="1" stop-color="#E9B10E" stopOpacity="0"></stop>
              </linearGradient>
              <filter
                id="spinner-tick-large_svg__b"
                width="18"
                height="14"
                x="202"
                y="0"
                colorInterpolationFilters="sRGB"
                filterUnits="userSpaceOnUse"
              >
                <feFlood
                  floodOpacity="0"
                  result="BackgroundImageFix"
                ></feFlood>
                <feColorMatrix
                  in="SourceAlpha"
                  result="hardAlpha"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                ></feColorMatrix>
                <feOffset></feOffset>
                <feGaussianBlur stdDeviation="2"></feGaussianBlur>
                <feComposite in2="hardAlpha" operator="out"></feComposite>
                <feColorMatrix values="0 0 0 0 0.913725 0 0 0 0 0.694118 0 0 0 0 0.054902 0 0 0 0.5 0"></feColorMatrix>
                <feBlend
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow_1504_13201"
                ></feBlend>
                <feBlend
                  in="SourceGraphic"
                  in2="effect1_dropShadow_1504_13201"
                  result="shape"
                ></feBlend>
              </filter>
            </defs>
                  </svg>
          <div className="empire-case-opening-roll-container-outer">
            {[...Array(quantity)].map((_, i) => (
  <div key={i} className="empire-case-opening-roll-container">
    <div
      className="empire-case-opening-roll-container-inner"
      id={`spinner-items-container-${i}`}
    >
      <SpinnerContainer
        spinnerItems={spinnerItems[i] || []}
        highlightIndex={highlightIndex}
        isVertical={isVertical}
      />
    </div>
  </div>
))}
          </div>
        </div>
        <div className="empire-case-opening-roll-buttons-bar">
          <button
            className="empire-case-opening-roll-skip-button"
            disabled={isSpinning}
            onClick={() => openDemoBox()}
          >
            <svg
              data-v-36d539dd=""
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="12"
              fill="currentColor"
              class="fill-current"
            >
              <path d="M6.3 5.267c.41.399.41 1.067 0 1.466L1.668 11.22c-.63.61-1.668.154-1.668-.733V1.512C0 .625 1.039.169 1.668.779L6.3 5.267ZM13.692 5.267c.41.399.41 1.067 0 1.466L9.06 11.22c-.63.61-1.668.154-1.668-.733V1.512C7.392.625 8.43.169 9.06.779l4.632 4.488Z"></path>
            </svg>
            <div>Skip Case</div>
          </button>
          <div className="empire-case-opening-roll-buttons-bar-right">
            <button
              className={`empire-case-opening-roll-buttons-bar-fast-spin ${
                fastSpin ? "fast-spin-checked" : ""
              }`}
              disabled={isSpinning}
              onClick={() => setfastSpin(!fastSpin)}
            >
              <svg
                data-v-36d539dd=""
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="14"
                fill="currentColor"
                class="fill-current"
              >
                <path
                  fillRule="evenodd"
                  d="M6.167 1.167A.583.583 0 0 0 5.079.873l-4.083 7a.583.583 0 0 0 .504.877h2.333v4.083a.584.584 0 0 0 1.088.294l4.083-7A.583.583 0 0 0 8.5 5.25H6.167V1.167Z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </button>
            <div className="empire-case-opening-roll-buttons-bar-right-inner">
              <div className="empire-case-opening-roll-buttons-count-container">
                {[...Array(maxQuantity).keys()].map((i) => (
                  <button
                    key={i}
                    className={`empire-case-opening-roll-buttons-count-button ${
                      quantity === i + 1 ? "selected" : ""
                    }`}
                    name="case-quantity"
                    disabled={isSpinning}
                    onClick={() => setSpinnerQuantity(i + 1)}
                  >
                    {i + 1}
                  </button>
                ))}
              </div>
              <div className="empire-case-opening-roll-open-container">
                <span className="empire-case-opening-roll-open-price-container">
                  <svg
                    data-v-5aeb787d=""
                    viewBox="0 0 22 22"
                    class="w-14 h-14 fill-current text-yellow-2 mr-sm my-auto"
                  >
                    <path d="M21.72 4a.86.86 0 0 0 0-.17c0-1.7-4.44-3.09-9.93-3.09S1.9 2.14 1.9 3.84a1.46 1.46 0 0 0 0 .22.86.86 0 0 1-.04-.06v2.55c0 .55.61 1.15 1.68 1.7 0 0 2.63 1.58 8.26 1.64a21.28 21.28 0 0 0 7-1.09c1.82-.64 2.91-1.46 2.91-2.19V4.05a.28.28 0 0 0 0-.09.08.08 0 0 1 .01.04ZM4.37 3.31c.73-1 3.78-1.71 7.43-1.71s6.78.75 7.46 1.74a.72.72 0 0 1 .14.41s0 .38 0 .41a2.55 2.55 0 0 0-.4-.32c-1.07-.76-3.9-1.2-7.18-1.2s-6 .4-7.1 1.14a1.73 1.73 0 0 0-.49.38v-.41a.77.77 0 0 1 .14-.44Zm15.52 6.46v2.55c0 .73-1.08 1.55-2.91 2.2a21 21 0 0 1-7 1.08c-5.67-.06-8.3-1.6-8.3-1.6C.61 13.42 0 12.82 0 12.27v-2.5a.28.28 0 0 1 0-.09.76.76 0 0 1 0-.16C0 9 .57 8.38 1.55 7.9V8c0 .58.67 1.21 1.85 1.78 0 0 2.58 1.38 8.1 1.55a19.56 19.56 0 0 0 7-1.08 10.48 10.48 0 0 0 1.32-.56 1 1 0 0 1-.08.28 1.59 1.59 0 0 0 .12-.27ZM22 15.41v2.5c0 .55-.61 1.15-1.68 1.7 0 0-2.63 1.57-8.27 1.63a21 21 0 0 1-7-1.08C3.19 19.52 2.11 18.7 2.11 18v-2.68a1.89 1.89 0 0 0 .09.21 10.35 10.35 0 0 0 1.23.52 19.78 19.78 0 0 0 7 1.08c5.52-.18 8.1-1.55 8.1-1.55 1.17-.57 1.85-1.2 1.85-1.78v-.08c1 .48 1.54 1 1.54 1.65v-.06a.28.28 0 0 1 .08.1Z"></path>
                  </svg>
                  {(caseInfo?.cost * quantity)?.toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </span>
                <button
                  className="empire-case-opening-roll-open-button"
                  onClick={() => openBox()}
                  disabled={isSpinning}
                >
                  Open
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const CaseContent = ({ caseInfo }) => {
  return (
    <div className="empire-case-content-container">
      <div className="empire-case-content-container-header">Case contains</div>
      <div className="empire-case-content-container-items pretty-scrollbar">
        {caseInfo.drops
          ?.sort((a, b) => b.item.value - a.item.value)
          .map((drop, index) => (
            <CaseContentItem key={uuidv4()} drop={drop} />
          ))}
      </div>
    </div>
  );
};

const CaseContentItem = ({ drop }) => {
  return (
    <div className="empire-case-content-container-item">
      <div
        className={`empire-case-content-container-item-glow ${getEmpireItemGlowClass(
          drop.item.value
        )}`}
      ></div>
      <img
        className="empire-case-content-container-item-image"
        src={drop.item.imageUrl}
        alt=""
      ></img>
      <div className="empire-case-content-container-item-info-container">
        <div className="empire-case-content-container-item-info">
          <div className="empire-case-content-container-item-category">
            {drop.item.brand}
          </div>
          <div className="empire-case-content-container-item-name">
            {drop.item.name}
          </div>
          <div className="empire-case-content-container-item-price-container">
            <span className="empire-case-content-container-item-price">
              <svg
                data-v-5aeb787d=""
                viewBox="0 0 22 22"
                class="w-14 h-14 fill-current text-yellow-2 mr-sm my-auto"
              >
                <path d="M21.72 4a.86.86 0 0 0 0-.17c0-1.7-4.44-3.09-9.93-3.09S1.9 2.14 1.9 3.84a1.46 1.46 0 0 0 0 .22.86.86 0 0 1-.04-.06v2.55c0 .55.61 1.15 1.68 1.7 0 0 2.63 1.58 8.26 1.64a21.28 21.28 0 0 0 7-1.09c1.82-.64 2.91-1.46 2.91-2.19V4.05a.28.28 0 0 0 0-.09.08.08 0 0 1 .01.04ZM4.37 3.31c.73-1 3.78-1.71 7.43-1.71s6.78.75 7.46 1.74a.72.72 0 0 1 .14.41s0 .38 0 .41a2.55 2.55 0 0 0-.4-.32c-1.07-.76-3.9-1.2-7.18-1.2s-6 .4-7.1 1.14a1.73 1.73 0 0 0-.49.38v-.41a.77.77 0 0 1 .14-.44Zm15.52 6.46v2.55c0 .73-1.08 1.55-2.91 2.2a21 21 0 0 1-7 1.08c-5.67-.06-8.3-1.6-8.3-1.6C.61 13.42 0 12.82 0 12.27v-2.5a.28.28 0 0 1 0-.09.76.76 0 0 1 0-.16C0 9 .57 8.38 1.55 7.9V8c0 .58.67 1.21 1.85 1.78 0 0 2.58 1.38 8.1 1.55a19.56 19.56 0 0 0 7-1.08 10.48 10.48 0 0 0 1.32-.56 1 1 0 0 1-.08.28 1.59 1.59 0 0 0 .12-.27ZM22 15.41v2.5c0 .55-.61 1.15-1.68 1.7 0 0-2.63 1.57-8.27 1.63a21 21 0 0 1-7-1.08C3.19 19.52 2.11 18.7 2.11 18v-2.68a1.89 1.89 0 0 0 .09.21 10.35 10.35 0 0 0 1.23.52 19.78 19.78 0 0 0 7 1.08c5.52-.18 8.1-1.55 8.1-1.55 1.17-.57 1.85-1.2 1.85-1.78v-.08c1 .48 1.54 1 1.54 1.65v-.06a.28.28 0 0 1 .08.1Z"></path>
              </svg>
              <div>
                {drop.item.value?.toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </div>
            </span>
            <div className="empire-case-content-container-item-probability">
              {(drop.probability * 100).toFixed(4)}%
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Body = () => {
  const [rewards, setRewards] = useState("");
  const [caseInfo, setCaseInfo] = useState({
    name: "Loading",
    image: "case.png",
  });
  const { caseId } = useParams();

  useEffect(() => {
    axios
      .get(`/cases/${caseId}`)
      .then((response) => {
        setCaseInfo(response.data);
      })
      .catch((error) => {
        console.error("Failed to fetch case info:", error);
      });
  }, [caseId]);

  return (
    <div className="empire-body-container">
      <div className="empire-case-opener">
        <CaseOpening
          maxQuantity={4}
          spinnerAmount={101}
          setRewards={setRewards}
          caseInfo={caseInfo}
        />
        <CaseContent caseInfo={caseInfo} />
      </div>
    </div>
  );
};

export const EmpireCaseOpener = () => {
  return <Body />;
};
