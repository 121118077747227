import { useAuth } from "../../contexts/AuthContext";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import React from 'react';
import "./Backpack.css";
import CoinImage from "../../images/coin.svg";
import axios from 'axios';
import { BalanceDisplay } from '../../global/Global';
import * as wear_pb from '../../global/Protos/Wear_pb.js';

const Backpack = ({ isOpen, close }) => {
    const [inventoryItems, setInventoryItems] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);

    const BackpackItem = ({ item }) => {

        const toggleCheckbox = () => {
            let copySelectedItems = [...(selectedItems || [])];
            if (selectedItems.some(x => x.id === item.id)) {
                copySelectedItems = copySelectedItems.filter(x => x.id !== item.id);
            } else {
                copySelectedItems.push(item);
            }
            setSelectedItems(copySelectedItems);
        };

        const isChecked = selectedItems ? selectedItems.some(x => x.id === item.id) : false;
        const wearString = Object.keys(wear_pb.Wear).find(key => wear_pb.Wear[key] === item.item.wear).replace('_', ' ');

        var glowClass = `item-glow item-glow-${item.item.rarity}`;

        return (
            <div className="backpack-item" onClick={toggleCheckbox}>
                <div className="backpack-item-checkbox-container">
                    <input
                        type="checkbox"
                        className="backpack-item-checkbox"
                        checked={isChecked}
                        readOnly>
                    </input>
                </div>
                <div className="backpack-item-image-container">
                    <div className={glowClass}></div>
                    <div className="backpack-item-image" style={{ backgroundImage: "url(" + item.item.imageUrl + ")" }}></div>
                </div>
                <div className="backpack-item-text-container">
                    <div className="backpack-item-wear">{wearString}</div>
                    <div className="backpack-item-name">{`${item.item.brand} | ${item.item.name}`}</div>
                    <div className="backpack-item-price">
                        <BalanceDisplay balance={item.item.value} ></BalanceDisplay>
                    </div>
                </div>
            </div>
        );
    };

    const selectAllItems = () => {
        setSelectedItems(inventoryItems);
    };

    const deselectAllItems = () => {
        setSelectedItems([]);
    };

    const loadInventoryItems = () => {
        setSelectedItems([]);
        axios.get('/inventory')
            .then(response => {
                setInventoryItems(response.data);
            })
            .catch(error => {
                console.error("Failed to fetch inventory items:", error);
            });
    };

    const { user, setUser } = useAuth();

    const sellSelectedItems = async () => {
        if (selectedItems.length === 0) {
            console.error("No items selected for selling.");
            return;
        }
        const itemIds = selectedItems.map(item => ({ value: item.id }));

        const expectedPay = {
            currency: selectedItems[0].item.currency,
            balance: parseFloat(totalSelectedItemsValue.toFixed(2))
        };

        try {
            let response = await axios.post("/inventory/sell", {
                itemIds: itemIds,
                expectedPay: expectedPay
            });

            if (response?.status === 200) {
                const newBalance = user.balances.map((balance) => {
                    if (balance.currency === expectedPay.currency) {
                        balance.amount += expectedPay.balance;
                    }
                    return balance;
                });
                setUser({ ...user, balances: newBalance });
            } else {
                console.error("Error selling items! Response status:", response?.status);
            }
        } catch (exception) {
            console.error("Exception occurred when selling items:", exception);
        }

        loadInventoryItems();
    };

    useEffect(() => {
        if (isOpen) {
            loadInventoryItems();
        }
    }, [isOpen]);

    if (!isOpen) return null;

    const totalSelectedItemsValue = selectedItems.reduce((acc, curr) => acc + curr.item.value, 0);

    return (
        <div className="backpack-overlay" onClick={close}>
            <div className="backpack" onClick={e => e.stopPropagation()}>
                <Link className="inventory-link">
                    <div className="inventory-link-left">
                        <cw-icon class="backpack-backpack-arrow">
                            <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg" className="inventory-icon">
                                <path fillRule="evenodd" clipRule="evenodd" d="M15.3532 7.86113V15.8263C15.3532 16.8324 14.553 17.6479 13.566 17.6479H4.65269C3.66569 17.6479 2.86551 16.8324 2.86551 15.8263V7.86113C3.61114 9.05347 4.91902 9.84665 6.40595 9.84665H7.752V10.2617H10.4667V9.84665H11.8127C13.2996 9.84665 14.6075 9.05347 15.3532 7.86113ZM15.9472 8.74712L17.5685 9.92312V15.711H15.9472V8.74712ZM2.35279 8.74712V15.711H0.73146V9.92312L2.35279 8.74712ZM18.3 6.62565V9.62303L15.9472 7.83986V6.62565H18.3ZM2.35279 6.62565V7.83986L0 9.62303V6.62565H2.35279ZM9.59007 7.61716V9.46248H8.6286V7.61716H9.59007ZM15.6472 0V5.06922C15.6472 5.60131 15.542 6.10808 15.3532 6.5704C14.7756 7.98373 13.4085 8.97772 11.8127 8.97772H10.4667V6.85625H7.752V8.97772H6.40595C4.8102 8.97772 3.44303 7.98373 2.86551 6.5704C2.67663 6.10808 2.57146 5.60131 2.57146 5.06922V0H15.6472Z" fill="white"></path>
                            </svg>
                        </cw-icon>
                        {inventoryItems.length} items in inventory
                    </div>
                    
                    <cw-icon class="backpack-icon-arrow" >
                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="100%" height="100%" viewBox="0 0 24 24">
                        <path fill="currentColor" d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"/>
                    </svg>
                    </cw-icon>
                </Link>

                {inventoryItems.length > 0 && (
                    <div className="backpack-selection-buttons">
                        <div
                            className={`backpack-select-all ${setSelectedItems != null && selectedItems.length === inventoryItems.length ? 'all-selected' : ''}`} onClick={selectAllItems}>
                        Select All</div>
                        <div className="backpack-deselect-all" onClick={deselectAllItems}>Deselect All</div>
                    </div>
                )}
                <div className="backpack-item-list-outer pretty-scrollbar">
                    <div className="backpack-item-list-inner">
                        {inventoryItems.sort((a, b) => b.id - a.id).map((item) => (
                            <BackpackItem item={item} key={item.id} />
                        ))}

                    </div>
                </div>
                {selectedItems.length > 0 ?
                <div className="backpack-footer-outer-container">
                    <div className="backpack-footer-inner-container">
                            <div className="backpack-footer-sell" onClick={sellSelectedItems}>
                                <div className="backpack-footer-sell-text">SELL {selectedItems.length} for
                                <img
                                    className="inline-coin-image"
                                    src={CoinImage}
                                    alt=""
                                    />{totalSelectedItemsValue.toFixed(2)}
                                </div>
                            </div>
                        <div className="backpack-footer-upgrade">Upgrade {selectedItems.length} item{selectedItems.length > 1 ? "s" : ""}</div>
                    </div>
                    </div>
                 : ""
                }
            </div>
        </div>
    );
};

export default Backpack;