import { Link, Navigate, useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import { useLogin } from "../../services/AuthService";
import LogoImage from "../../images/logo.png";
import "./Login.css";
import { useState } from "react";

const LoginPage = () => {
  const { user } = useAuth();
  const { login, loading } = useLogin();
  const navigate = useNavigate();
  const [error, setError] = useState();

  const signIn = async (event) => {
    try {
      event.preventDefault();
      const [email, password] = event.target.elements;

      console.log(`EMAIL = ${email}`);
      if (!email.value || !password.value) {
        return;
      }

      await login(email.value, password.value);
      navigate("/");
    } catch (err) {
      console.error(`Error during Login: ${err}`);
      const { code, message } = err.response?.data;
      setError(`${message} (${code})`);
    }
  };

  if (user) {
    return <Navigate to="/" />;
  }

  return (
    <div className="login-page-container">
      <img class="login-page-logo" src={LogoImage} alt="" />
      <div style={{ textAlign: "center" }}>
        <p>
          During development, the database will be recreated on every new
          version.
        </p>
        <p>If you cannot login, please, register again.</p>
      </div>
      {error ? (
        <marquee
          className="login-error"
          style={{ width: "auto", textAlign: "center" }}
        >
          {error}
          <p>
            <h3>fix m-me pws uwu senpai</h3>
            <h3>(3O.o)3</h3>
          </p>
        </marquee>
      ) : (
        <></>
      )}
      <form className="login-form" onSubmit={signIn}>
        <input type="text" className="input" placeholder="Email" required />
        <input
          type="password"
          className="input"
          placeholder="Password"
          required
        />
        <button type="submit" className="button">
          Log In
        </button>
        <div>
          Don't have an account?
          <Link to="/signup" className="login-reg-link">
            Sign Up
          </Link>
        </div>
      </form>
    </div>
  );
};

export default LoginPage;
