import { NavMenu } from "./nav_menu/NavMenu";
import { Chat } from "./chat/Chat";
import { SignalRProvider } from "../contexts/SignalRContext";
import { GlobalUpdates } from "./GlobalUpdates";
import "./Layout.css";

export const Layout = ({ children }) => {
  return (
    <SignalRProvider>
      <GlobalUpdates>
        <div className="layout-container">
          <NavMenu />
          <Chat />
          {children}
        </div>
      </GlobalUpdates>
    </SignalRProvider>
  );
};
