import React, { useState, useEffect } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { Link } from "react-router-dom";
import axios from "axios";
import "./CaseSelector.css";
import { BalanceDisplay } from "../../global/Global";

const CaseDisplay = ({ caseInfo, selectedCases, onAddCase, onRemoveCase }) => {
  const matchingCase = selectedCases.find((sc) => sc.id === caseInfo.id);

  return (
    <div className="case-selector-case-display">
      <div className="case-selector-case-display-upper">
        <div className="case-selector-case-image-container">
          <img
            className="case-selector-case-image"
            src={require(`../../images/cases/${caseInfo.image}`)}
            alt=""
          ></img>
        </div>
        <div className="case-selector-case-display-details">
          <div className="case-selector-case-display-name">{caseInfo.name}</div>
          <BalanceDisplay balance={caseInfo.cost}></BalanceDisplay>
        </div>
      </div>
      {matchingCase ? (
        <div className="case-selector-case-display-counter-container">
          <div
            className="case-selector-case-display-counter-button"
            onClick={onRemoveCase}
          >
            -
          </div>
          <div className="case-selector-case-display-counter-inner">
            {matchingCase.amount}
          </div>
          <div
            className="case-selector-case-display-counter-button"
            onClick={onAddCase}
          >
            +
          </div>
        </div>
      ) : (
        <button
          className="case-selector-case-display-button button"
          onClick={onAddCase}
        >
          Add
        </button>
      )}
    </div>
  );
};

const CaseSelector = ({ isOpen, close, selectedCases, setSelectedCases }) => {
  const [searchText, setSearchText] = useState("");
  const [cases, setCases] = useState([]);

  useEffect(() => {
    axios
      .get("/cases")
      .then((response) => {
        setCases(
          response.data.filter((caseItem) => {
            return (
              caseItem.levelRequired === 1 && caseItem.tags.includes("csgoroll")
            );
          })
        );
      })
      .catch((error) => {
        console.error("Failed to fetch cases:", error);
      });
  }, []);

  const filteredCases = cases.filter((caseItem) => {
    return caseItem.name.toLowerCase().includes(searchText.toLowerCase());
  });

  const handleAddCase = (caseToAdd) => {
    const existingCaseIndex = selectedCases.findIndex(
      (sc) => sc.id === caseToAdd.id
    );

    if (existingCaseIndex !== -1) {
      const updatedSelectedCases = [...selectedCases];
      updatedSelectedCases[existingCaseIndex] = {
        ...updatedSelectedCases[existingCaseIndex],
        amount: updatedSelectedCases[existingCaseIndex].amount + 1,
      };
      setSelectedCases(updatedSelectedCases);
    } else {
      setSelectedCases([...selectedCases, { ...caseToAdd, amount: 1 }]);
    }
  };

  const handleRemoveCase = (caseToRemove) => {
    const existingCaseIndex = selectedCases.findIndex(
      (sc) => sc.id === caseToRemove.id
    );

    if (
      existingCaseIndex !== -1 &&
      selectedCases[existingCaseIndex].amount > 1
    ) {
      const updatedSelectedCases = [...selectedCases];
      updatedSelectedCases[existingCaseIndex] = {
        ...updatedSelectedCases[existingCaseIndex],
        amount: updatedSelectedCases[existingCaseIndex].amount - 1,
      };
      setSelectedCases(updatedSelectedCases);
    } else if (existingCaseIndex !== -1) {
      setSelectedCases(
        selectedCases.filter((_, index) => index !== existingCaseIndex)
      );
    }
  };

  if (!isOpen) return null;

  const totalBoxesAdded = selectedCases.reduce(
    (total, sc) => total + sc.amount,
    0
  );

  const totalCost = selectedCases.reduce((total, caseItem) => {
    return total + caseItem.cost * caseItem.amount;
  }, 0);

  return (
    <div className="case-selector-overlay" onClick={close}>
      <div className="case-selector" onClick={(e) => e.stopPropagation()}>
        <div className="case-selector-header">
          <div className="case-selector-header-left">
            <div className="case-selector-search-container">
              <input
                className="case-selector-search-input"
                spellCheck="false"
                placeholder="Search Case..."
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
            </div>
          </div>
          <div className="case-selector-header-right"></div>
        </div>
        <div className="case-selector-body">
          <div className="case-selector-body-grid-container">
            <div className="case-selector-body-grid">
              {filteredCases.map((caseItem, index) => (
                <CaseDisplay
                  caseInfo={caseItem}
                  key={caseItem.id}
                  selectedCases={selectedCases}
                  onAddCase={() => handleAddCase(caseItem)}
                  onRemoveCase={() => handleRemoveCase(caseItem)}
                />
              ))}
            </div>
          </div>
        </div>
        <div className="case-selector-footer">
          <div className="case-selector-footer-left">
            <span className="case-selector-footer-left-span">
              {totalBoxesAdded} BOXES ADDED
            </span>
            TOTAL VALUE
            <BalanceDisplay
              balance={totalCost}
              displayFree={false}
            ></BalanceDisplay>
          </div>
          <div className="case-selector-footer-right">
            <button
              className="button case-selector-footer-right-button"
              onClick={close}
            >
              Done
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CaseSelector;
