import React, { useEffect, useState, useRef } from "react";
import { getEmpireLevelTier, getHighestRank } from "../../global/Global";
import { useAuth } from "../../contexts/AuthContext";
import { useSignalR } from "../../contexts/SignalRContext";
import "./Chat.css";

const ChatMessage = React.memo(({ username, imageUrl, level, playerStaffRoles, message, onMentionUser, isMentioned, currentUser }) => {

    const highestRank = getHighestRank(playerStaffRoles);
    const parseMessageWithMentions = (message) => {
        const parts = message.split(/(@\w+)/);
        return parts.map((part, index) => {
            if (part.startsWith("@")) {
                const mentionedUser = part.slice(1);
                const mentionStyle = { color: "rgb(233 177 14)", background: "#e9b10e1a", padding: "0 3px 1px" };
                return (
                    <span key={index} style={mentionStyle}>
                        {part}
                    </span>
                );
            }
            return part;
        });
    };

    return (
        <div className="chat-message">
            { isMentioned ? <div className="chat-message-mention"></div> : "" }
            <img className="chat-message-avatar" src={imageUrl} alt=""></img>
            <div className="chat-message-content-wrapper">
                <span className="chat-message-empire-rank">
                    {highestRank ? (
                        <div className={`chat-message-empire-rank-inner chat-message-empire-rank-inner-${highestRank}`}>
                            <div>{highestRank.toUpperCase()}</div>
                        </div>
                    ) : (
                        <div
                            className={`chat-message-empire-rank-inner ${getEmpireLevelTier(
                                "",
                                level
                            )} ${getEmpireLevelTier("bg-", level)}`}
                        >
                            <img className="rank-image" alt=""></img>
                            <div className={getEmpireLevelTier("color-", level)}>{level}</div>
                        </div>
                    )}
                </span>
                <span className="chat-message-name" onClick={() => onMentionUser(username)} style={{ cursor: "pointer" }}>{username}</span>
                <span className="chat-message-text">{parseMessageWithMentions(message)}</span>
            </div>
        </div>
    );
});


const MessageInput = ({ onSendMessage, messageInput, setMessageInput }) => {

    const handleInputChange = (e) => {
        setMessageInput(e.target.value);
        resizeTextarea(e.target);
    };

    const handleKeyDown = (e) => {
        if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            onSendMessage(messageInput);
            setMessageInput("");
        }
    };

    const resizeTextarea = (element) => {
        element.style.height = "auto";
        element.style.height = element.scrollHeight + "px";
    };

    return (
        <div className="chat-input-outer-container">
            <div className="chat-input-inner-container">
                <textarea
                    className="chat-input-textarea"
                    cols="30"
                    rows="1"
                    maxLength="100"
                    placeholder="Type message..."
                    spellCheck="false"
                    value={messageInput}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                />
                <div className="chat-input-settings">
                    <svg
                        data-v-36d539dd=""
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        fill="currentcolor"
                        class="fill-current"
                    >
                        <path
                            d="M7 3.554A1.777 1.777 0 1 0 7 0a1.777 1.777 0 0 0 0 3.554ZM8.777 7a1.777 1.777 0 1 1-3.554 0 1.777 1.777 0 0 1 3.554 0Zm0 5.223a1.777 1.777 0 1 1-3.554 0 1.777 1.777 0 0 1 3.554 0Z"
                            clipRule="evenodd"
                        ></path>
                    </svg>
                </div>
            </div>
        </div>
    );
};

export const Chat = () => {
    const signalRConnection = useSignalR();
    const { user } = useAuth();
    const [messages, setMessages] = useState([]);
    const [messageInput, setMessageInput] = useState(""); // State to manage message input
    const messagesEndRef = useRef(null);

    useEffect(() => {
        if (signalRConnection) {
            signalRConnection.on("ReceiveMessage", handleMessage);
        }

        return () => {
            if (signalRConnection) {
                signalRConnection.off("ReceiveMessage", handleMessage);
            }
        };
    }, [signalRConnection]);

    useEffect(() => {
        const shouldScroll =
            messagesEndRef.current.scrollTop + messagesEndRef.current.clientHeight >=
            messagesEndRef.current.scrollHeight - 100;

        if (shouldScroll) {
            messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
        }
    }, [messages]);

    const handleMessage = (userInfo, receivedMessageId, receivedMessage) => {
        setMessages((prevMessages) => [
            ...prevMessages,
            {
                userInfo: userInfo,
                messageId: receivedMessageId,
                message: receivedMessage,
            },
        ]);
    };

    const sendMessage = (message) => {
        if (message.trim() === "") {
            return;
        }

        signalRConnection.invoke("SendMessage", message);

        const textarea = document.querySelector(".chat-input-textarea");
        if (textarea) {
            textarea.style.height = "auto";
        }
    };

    const handleMentionUser = (username) => {
        setMessageInput((prevInput) => `${prevInput} @${username} `); // Add mention to input field
    };

    return (
        <div className="empire-chat">
            <div className="chat-inner-container">
                <div
                    className="chat-messages-container scroll-y scroll-y--contain"
                    ref={messagesEndRef}
                >
                    {messages.map((x) => {

                        const isMentioned = x.message.includes(`@${user.user.username}`);

                        return (
                            <ChatMessage
                                key={x.messageId}
                                username={x.userInfo.username}
                                imageUrl={x.userInfo.imageUrl}
                                level={x.userInfo.level}
                                playerStaffRoles={x.userInfo.playerStaffRoles}
                                message={x.message}
                                onMentionUser={handleMentionUser}
                                isMentioned={isMentioned}
                                currentUser={user}
                            />
                        );
                    })}
                </div>
                <MessageInput onSendMessage={sendMessage} messageInput={messageInput} setMessageInput={setMessageInput} />
            </div>
        </div>
    );
};
