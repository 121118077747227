import React, { useState, useEffect } from 'react';
import axios from 'axios';
import "./DicePage.css";
import BalanceDisplay from '../../global/Global';
import { Link } from "react-router-dom";


const ToggleSwitch = ({ text, isToggled, onToggle }) => {
    return (
        <button className="dice-toggle" onClick={() => onToggle(text)}>
            {text}
            <div className={`toggle-switch ${isToggled ? 'switch-on' : ''}`}>
                <div className="toggle-switch-track"></div>
                <div className="toggle-switch-knob"></div>
            </div>
        </button>
    );
};


const Body = () => {

    const [activeToggle, setActiveToggle] = useState('');

    const handleToggle = (name) => {
        setActiveToggle(activeToggle === name ? '' : name);
    };

    //useEffect(() => {
    //    // If you need to do something when the active toggle changes, you can do it here.
    //    console.log(activeToggle);
    //}, [activeToggle]);

    return (
        <div className="body-container">
            <div className="middle-container">
                <div className="dice-container">
                    <div className="dice-toggles">
                        <ToggleSwitch text="Spray" isToggled={activeToggle === 'Spray'} onToggle={handleToggle} />
                        <ToggleSwitch text="Auto" isToggled={activeToggle === 'Auto'} onToggle={handleToggle} />
                        <ToggleSwitch text="Slow Roll" isToggled={activeToggle === 'Slow Roll'} onToggle={handleToggle} />
                    </div>
                </div>
                <div className="dice-bets-container"></div>
            </div>
        </div>
    );

};

export const DicePage = () => {
    return <Body />;
};