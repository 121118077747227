import React, { useEffect, useState } from "react";
import { AvatarDisplay, getHighestRank } from "../../global/Global";
import { useSignalR } from "../../contexts/SignalRContext";
import { useAuth } from "../../contexts/AuthContext";
import trashSvg from "../../images/trash-347.svg";
import "./Chat.css";

const ChatMessage = React.memo(({ messageId, username, imageUrl, level, playerStaffRoles, message, onDeleteMessage, onMentionUser, isMentioned, currentUser }) => {
    const highestRank = getHighestRank(playerStaffRoles);
    const parseMessageWithMentions = (message) => {
        const parts = message.split(/(@\w+)/);
        return parts.map((part, index) => {
            if (part.startsWith("@")) {
                const mentionedUser = part.slice(1);
                const mentionStyle = mentionedUser === currentUser.user.username ? { color: "yellow" } : { color: "grey" }; // Yellow if it's the current user, grey otherwise
                return (
                    <span key={index} style={mentionStyle}>
                        {part}
                    </span>
                );
            }
            return part;
        });
    };

    return (
        <div
            className="chat-message">
            <AvatarDisplay imageUrl={imageUrl} level={level} playerStaffRoles={playerStaffRoles} />
            <div className="chat-message-content-wrapper">
                <div className="chat-message-header">
                    {highestRank ? (
                        <div className={`chat-message-rank-inner chat-message-rank-inner-${highestRank}`}>
                            <div>{highestRank.toLocaleLowerCase()}</div>
                        </div>
                    ) : ""}
                    <div
                        className="chat-message-name"
                        onClick={() => onMentionUser(username)} // Add mention on username click
                        style={{ cursor: "pointer" }} // Change cursor to pointer for better UX
                    >
                        {username}
                    </div>
                    <div className="chat-message-moderation-icons">
                        <img
                            alt="Delete"
                            src={trashSvg}
                            onClick={() => onDeleteMessage(messageId)} // Add onClick handler
                            style={{ cursor: "pointer" }} // Change cursor to pointer for better UX
                        />
                    </div>
                </div>
                <div className="chat-message-text" style={{
                    backgroundColor: isMentioned ? "#ffe0630d" : "#292e35", // Highlight if the user is mentioned
                }} >{parseMessageWithMentions(message)}</div>
            </div>
        </div>
    );
});



const MessageInput = ({ onSendMessage, messageInput, setMessageInput }) => {

    const handleInputChange = (e) => {
        setMessageInput(e.target.value);
        resizeTextarea(e.target);
    };

    const handleKeyDown = (e) => {
        if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            onSendMessage(messageInput);
            setMessageInput("");
        }
    };

    const sendMessage = () => {
        onSendMessage(messageInput);
        setMessageInput("");
    };

    const resizeTextarea = (element) => {
        element.style.height = "auto";
        element.style.height = element.scrollHeight + "px";
    };

    return (
        <div className="chat-input-outer-container">
            <div className="chat-input-inner-container">
                <textarea
                    className="chat-input-textarea"
                    cols="30"
                    rows="1"
                    maxLength="100"
                    placeholder="Type message..."
                    spellCheck="false"
                    value={messageInput}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                />
            </div>
            <button onClick={() => sendMessage()} className="chat-send-button">
                <cw-icon _ngcontent-ng-c1080427604="">
                    <svg
                        viewBox="0 0 21 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M17.9583 9.96289L5.0119 2.85718V8.1067L12.564 9.96289L5.0119 11.8191V17.1429L17.9583 9.96289Z"
                            fill="currentColor"
                            fillOpacity="0.5"
                        />
                    </svg>
                </cw-icon>
            </button>
        </div>
    );
};


export const Chat = () => {
    const signalRConnection = useSignalR();
    const { user } = useAuth(); // Assume useAuth provides the current user's info
    const [messages, setMessages] = useState([]);
    const [messageInput, setMessageInput] = useState(""); // State to manage message input

    useEffect(() => {
        if (signalRConnection) {
            signalRConnection.on("ReceiveMessage", handleMessage);
            signalRConnection.on("DeleteMessage", handleDeleteMessage); // Listen for delete events
        }

        return () => {
            if (signalRConnection) {
                signalRConnection.off("ReceiveMessage", handleMessage);
                signalRConnection.off("DeleteMessage", handleDeleteMessage); // Clean up delete events
            }
        };
    }, [signalRConnection]);

    const handleMessage = (userInfo, receivedMessageId, receivedMessage) => {
        setMessages((prevMessages) => [
            ...prevMessages,
            {
                userInfo: userInfo,
                messageId: receivedMessageId,
                message: receivedMessage,
            },
        ]);
    };

    const handleDeleteMessage = (messageId) => {
        setMessages((prevMessages) => prevMessages.filter((msg) => msg.messageId !== messageId));
    };

    const sendMessage = (message) => {
        if (message.trim() === "") {
            return;
        }

        signalRConnection.invoke("SendMessage", message);

        const textarea = document.querySelector(".chat-input-textarea");
        if (textarea) {
            textarea.style.height = "auto";
        }
    };

    const deleteMessage = (messageId) => {
        signalRConnection.invoke("DeleteMessage", messageId) // Call the SignalR method to delete the message
            .then(() => handleDeleteMessage(messageId)) // Optimistically update the UI on success
            .catch((error) => console.error("Failed to delete message:", error));
    };

    const handleMentionUser = (username) => {
        setMessageInput((prevInput) => `${prevInput} @${username} `); // Add mention to input field
    };

    return (
        <div className="csgoroll-chat">
            <div className="chat-inner-container">
                <div className="chat-messages-container pretty-scrollbar">
                    {messages.map((x) => {

                        const isMentioned = x.message.includes(`@${user.user.username}`); // Check if the current user is mentioned

                        return (
                            <ChatMessage
                                key={x.messageId}
                                messageId={x.messageId} // Pass messageId prop
                                username={x.userInfo.username}
                                imageUrl={x.userInfo.imageUrl}
                                level={x.userInfo.level}
                                playerStaffRoles={x.userInfo.playerStaffRoles}
                                message={x.message}
                                onDeleteMessage={deleteMessage} // Pass delete handler prop
                                onMentionUser={handleMentionUser} // Pass mention handler prop
                                isMentioned={isMentioned} // Pass isMentioned prop
                                currentUser={user} // Pass current user to determine mention style
                            />
                        );
                    })}
                </div>
                <MessageInput onSendMessage={sendMessage} messageInput={messageInput} setMessageInput={setMessageInput} />
            </div>
        </div>
    );
};



