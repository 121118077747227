import React, { StrictMode } from "react";
import { Route, Routes } from "react-router-dom";
import { PublicRoutes, PrivateRoutes, PrivateRoute } from "./AppRoutes";
import { AuthProvider } from "./contexts/AuthContext";
import { Layout } from "./components/Layout";
import "./custom.css";

export const App = () => {
  return (
    <StrictMode>
      <AuthProvider>
        {/* <Layout> */}
        <Routes>
          {PublicRoutes.map((route, index) => {
            const { element, ...rest } = route;
            return <Route key={index} {...rest} element={element} />;
          })}
          <Route element={<PrivateRoute />}>
            {PrivateRoutes.map((route, index) => {
              const { element, ...rest } = route;
              return <Route key={index} {...rest} element={element} />;
            })}
          </Route>
        </Routes>
        {/* </Layout> */}
      </AuthProvider>
    </StrictMode>
  );
};
