import CoinImage from "../../images/coin.svg";
import GemImage from "../../images/gem.svg";
import LogoImage from "../../images/logo.png";
import { useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { useLocation, Link } from "react-router-dom";
import "./NavMenu.css";
import Backpack from "../../components/backpack/Backpack";
import NavPlayerDropdown from "../../components/nav_player_dropdown/NavPlayerDropdown";
import { getLevelTier } from "../../global/Global";

export const NavMenu = () => {
  const location = useLocation();
  const { user } = useAuth();

  const tokens = user.balances?.find((x) => x.currency === 1);
  const gems = user.balances?.find((x) => x.currency === 2);

  const [isBackpackOpen, setBackpackOpen] = useState(false);
  const [isPlayerDropdownOpen, setPlayerDropdownOpen] = useState(false);

  const openBackpack = () => {
    setBackpackOpen(true);
  };

  const closeBackpack = () => {
    setBackpackOpen(false);
  };

  const openPlayerDropdown = () => {
    setPlayerDropdownOpen(true);
  };

  const closePlayerDropdown = () => {
    setPlayerDropdownOpen(false);
  };

  return (
    <div className="header">
      <div className="header-left">
        <Link to="/" className="header-logo-link">
          <img className="header-logo" src={LogoImage} alt=""></img>
        </Link>
        <div className="nav-item">
          <Link
            to="/unboxing"
            className={`nav-item-link ${
              location.pathname === "/unboxing" ? "nav-item-active" : ""
            }`}
          >
            <cw-icon
              _ngcontent-ng-c2014194555=""
              class="nav-item-icon ng-tns-c2014194555-2 ng-star-inserted"
            >
              <svg
                id="Gold"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
              >
                <g id="noun_Rocket_2217234" transform="translate(0.481 0.49)">
                  <g id="Group" transform="translate(0)">
                    <path
                      fill="currentColor"
                      id="Path"
                      d="M4.2,2.009a1.109,1.109,0,0,1-.664.443,1.975,1.975,0,0,0-.933.3.14.14,0,0,1-.221-.079,1.227,1.227,0,0,1,.174-.838.361.361,0,0,0-.174.047.149.149,0,0,1-.206-.158,1.72,1.72,0,0,1,.7-1.012l-.6-.6L2.161,0C.99.253.262,1.645.009,2.594a.324.324,0,0,0,.459.364.858.858,0,0,1,.4-.095,2.857,2.857,0,0,0-.4,1.961.323.323,0,0,0,.522.19,4.684,4.684,0,0,1,2.183-.7,2.439,2.439,0,0,0,1.8-1.534,1.9,1.9,0,0,1-.19-.174Z"
                      transform="translate(0 9.954)"
                    ></path>
                    <path
                      fill="currentColor"
                      id="Shape"
                      d="M7.5,12.991l-.7-1.9A19.356,19.356,0,0,1,4.744,12.2a.869.869,0,0,1-.981-.174l-2.5-2.5a.9.9,0,0,1-.174-.981A23.064,23.064,0,0,1,2.2,6.49l-1.9-.7a.472.472,0,0,1-.19-.743L1.6,3.295a2.408,2.408,0,0,1,2.658-.7l.855.317A12.7,12.7,0,0,1,12.827,0a.532.532,0,0,1,.316.143.455.455,0,0,1,.143.316,12.809,12.809,0,0,1-2.91,7.72l.3.854a2.408,2.408,0,0,1-.7,2.658L8.24,13.181a.461.461,0,0,1-.306.117A.467.467,0,0,1,7.5,12.991Z"
                      transform="translate(1.561 0)"
                    ></path>
                  </g>
                </g>
              </svg>
            </cw-icon>
            <span>Games</span>
            <cw-icon
              _ngcontent-ng-c2014194555=""
              inlinesvg="assets/icons/chevron-down-solid.svg"
              class="chevron ng-tns-c2014194555-2 ng-star-inserted"
            >
              <svg
                width="0.75rem"
                height="12"
                viewBox="0 0 10 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7.56551 2.99996C7.56551 3.0885 7.52905 3.17183 7.46655 3.23433L5.13322 5.56767C5.07072 5.63017 4.98739 5.66662 4.89884 5.66662C4.8103 5.66662 4.72697 5.63017 4.66447 5.56767L2.33114 3.23433C2.26864 3.17183 2.23218 3.0885 2.23218 2.99996C2.23218 2.81767 2.38322 2.66663 2.56551 2.66663H7.23218C7.41447 2.66663 7.56551 2.81767 7.56551 2.99996Z"
                  fill="currentColor"
                ></path>
              </svg>
            </cw-icon>
          </Link>
          <div className="nav-games-dropdown">
            <div className="nav-games-dropdown-game">
              <Link className="nav-games-dropdown-game-link">
                <img
                  className="nav-games-dropdown-game-img"
                  alt=""
                  src="https://images.takeshape.io/0ffaf7c3-289e-4df3-a6aa-4c513d356c76/dev/fb3b059f-9db1-4de8-ae88-bff2b79161e3/roll.png?auto=format&auto=format&w=320"
                ></img>
                <div className="nav-games-dropdown-game-name">Roll</div>
              </Link>
            </div>
            <div className="nav-games-dropdown-game">
              <Link className="nav-games-dropdown-game-link">
                <img
                  className="nav-games-dropdown-game-img"
                  alt=""
                  src="https://images.takeshape.io/0ffaf7c3-289e-4df3-a6aa-4c513d356c76/dev/a891f6f0-18cd-4d38-8253-24ad97fcfa54/crash.png?auto=format&auto=format&w=320"
                ></img>
                <div className="nav-games-dropdown-game-name">Crash</div>
              </Link>
            </div>
            <div className="nav-games-dropdown-game">
              <Link className="nav-games-dropdown-game-link">
                <img
                  className="nav-games-dropdown-game-img"
                  alt=""
                  src="https://images.takeshape.io/0ffaf7c3-289e-4df3-a6aa-4c513d356c76/dev/2c30d481-6bc9-41cf-896f-8796241f78bb/plinko.png?auto=format&auto=format&w=320"
                ></img>
                <div className="nav-games-dropdown-game-name">Plinko</div>
              </Link>
            </div>
            <div className="nav-games-dropdown-game">
              <Link to="/unboxing" className="nav-games-dropdown-game-link">
                <img
                  className="nav-games-dropdown-game-img"
                  alt=""
                  src="https://images.takeshape.io/0ffaf7c3-289e-4df3-a6aa-4c513d356c76/dev/a129637f-a498-4c52-90cd-384f6794b28b/unboxing.png?auto=format&auto=format&w=320"
                ></img>
                <div className="nav-games-dropdown-game-name">Unboxing</div>
              </Link>
            </div>
            <div className="nav-games-dropdown-game">
              <Link to="/case-battles" className="nav-games-dropdown-game-link">
                <img
                  className="nav-games-dropdown-game-img"
                  alt=""
                  src="https://images.takeshape.io/0ffaf7c3-289e-4df3-a6aa-4c513d356c76/dev/5e45f68c-9a26-4658-a2bf-1a8800441288/pvp.png?auto=format&auto=format&w=320"
                ></img>
                <div className="nav-games-dropdown-game-name">Case battles</div>
              </Link>
            </div>
            <div className="nav-games-dropdown-game">
              <Link className="nav-games-dropdown-game-link">
                <img
                  className="nav-games-dropdown-game-img"
                  alt=""
                  src="https://images.takeshape.io/0ffaf7c3-289e-4df3-a6aa-4c513d356c76/dev/7f2f0d15-dc98-4ee6-ae60-c056af49eb5f/esports.png?auto=format&auto=format&w=320"
                ></img>
                <div className="nav-games-dropdown-game-name">E-sports</div>
              </Link>
            </div>
            <div className="nav-games-dropdown-game">
              <Link to="/dice" className="nav-games-dropdown-game-link">
                <img
                  className="nav-games-dropdown-game-img"
                  alt=""
                  src="https://images.takeshape.io/0ffaf7c3-289e-4df3-a6aa-4c513d356c76/dev/1ffa8ce0-79a4-4521-a13e-c7889b07230b/dice.png?auto=format&auto=format&w=320"
                ></img>
                <div className="nav-games-dropdown-game-name">Dice</div>
              </Link>
            </div>
          </div>
        </div>
        <div className="nav-item">
          <Link className="nav-item-link">
            <cw-icon _ngcontent-ng-c2014194555="" class="nav-item-icon">
              <svg width="16" height="14" xmlns="http://www.w3.org/2000/svg">
                <g transform="translate(0 -1)" fill="none" fillRule="evenodd">
                  <path
                    d="M15.662 7.173c-.785-3.875-1.892-4.83-2.566-5.213-1.079-.614-2.33-.257-2.745-.026-.471.262-.79.76-1.268.976-.554.249-1.307.316-1.873.016-.451-.24-.85-.752-1.396-.979-.693-.288-1.847-.692-2.99.19-.27.21-2.196 1.95-2.775 7.387-.357 3.352 1.349 4.43 1.286 4.382.44.338 1.422.911 2.753.014.89-.6 1.612-1.783 1.612-1.783s.322-.659.75-.939c.302-.198.755-.047.755-.047h1.6s.51-.07.866.1c.347.164.542.566.542.566s1.056 1.47 2.067 2.219c.43.318 2.161.684 3.055-.492.583-.77.981-3.136.327-6.37ZM7.2 7.015c0 .317-.22.574-.492.574H5.477v1.436c0 .318-.22.575-.492.575h-.37c-.271 0-.491-.257-.491-.575V7.59H2.892c-.272 0-.492-.256-.492-.574v-.43c0-.318.22-.575.492-.575h1.231V4.575c0-.317.22-.575.492-.575h.37c.271 0 .491.257.491.575V6.01h1.231c.272 0 .493.257.493.575v.43ZM10.4 8.8a.8.8 0 1 1 0-1.6.8.8 0 0 1 0 1.6Zm2.4-2.4a.8.8 0 1 1 0-1.6.8.8 0 0 1 0 1.6Z"
                    fill="currentColor"
                    fillRule="nonzero"
                  ></path>
                </g>
              </svg>
            </cw-icon>
            <span>Esports</span>
          </Link>
        </div>
        <div className="nav-item">
          <Link to="/case-battles" className="nav-item-link">
            <cw-icon
              _ngcontent-ng-c2014194555=""
              class="nav-item-icon ng-tns-c2014194555-2 ng-star-inserted"
            >
              <svg viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill="currentColor"
                  d="M11.241 6.476l3.014 3.254 5.118-4.743.161-3.665-3.666.161-4.627 4.993zm-3.935 4.247l-1.948 2.102-1.499-1.5-.929.93 2.086 2.086-3.024 3.025 1.497 1.498 3.025-3.025 2.088 2.086.928-.93-1.5-1.497 2.214-2.051-2.938-2.724zm11.29 1.533l-.931-.928-1.498 1.5L5.657 1.482 1.99 1.324l.162 3.665 11.342 10.51-1.5 1.5.928.927 2.088-2.086 3.024 3.026 1.5-1.5-3.025-3.024 2.087-2.086z"
                ></path>
              </svg>
            </cw-icon>
            <span>PVP</span>
          </Link>
        </div>
        <div className="nav-item">
          <Link className="nav-item-link">
            <cw-icon
              _ngcontent-ng-c2014194555=""
              class="nav-item-icon ng-tns-c2014194555-2 ng-star-inserted"
            >
              <svg
                width="16px"
                height="16px"
                viewBox="0 0 16 16"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
              >
                <g
                  id="Symbols"
                  stroke="none"
                  strokeWidth="1"
                  fill="none"
                  fillRule="evenodd"
                >
                  <g
                    id="Global-/-Navigation"
                    transform="translate(-547.000000, -27.000000)"
                  >
                    <rect
                      id="Container"
                      fill="none"
                      transform="translate(720.000000, 35.000000) scale(-1, -1) translate(-720.000000, -35.000000) "
                      x="0"
                      y="0"
                      width="1440"
                      height="70"
                    ></rect>
                    <g id="Group-8" transform="translate(527.000000, 0.000000)">
                      <rect
                        id="Container"
                        transform="translate(67.000000, 35.000000) scale(-1, -1) translate(-67.000000, -35.000000) "
                        x="0"
                        y="0"
                        width="134"
                        height="70"
                      ></rect>
                      <g id="Gold" transform="translate(20.000000, 27.000000)">
                        <g fill="pink">
                          <path
                            d="M11.8100935,1.05340467 C11.7661901,0.923883449 11.6491141,0.83753597 11.5174037,0.808753477 C11.3856933,0.779970984 11.2393484,0.823144723 11.1515415,0.923883449 L7.63926371,4.69439004 L2.57572996,6.59403458 C1.88790891,6.85307702 1.34643275,7.35677064 1.03910845,8.01876798 C0.746418639,8.68076532 0.717149658,9.4147189 0.980570489,10.0911075 C1.39033623,11.1416685 2.41475057,11.8324483 3.55624083,11.8324483 C3.89283412,11.8324483 4.21479291,11.7748833 4.5367517,11.6597534 L5.0050554,11.4870584 L7.99049148,15.0560875 C8.06366394,15.142435 8.18073986,15.2 8.29781579,15.2 C8.34171926,15.2 8.38562273,15.1856088 8.44416069,15.1712175 L10.6393343,14.3509165 C10.7564102,14.3077427 10.8442171,14.207004 10.8734861,14.091874 C10.9027551,13.976744 10.8734861,13.8472228 10.8003137,13.7464841 L7.99049148,10.3645412 L9.60028545,9.76010881 L14.7662606,10.2638024 C14.897971,10.2781937 15.0296815,10.2206287 15.1174884,10.11989 C15.2052953,10.0191513 15.2199298,9.87523879 15.1760264,9.74571757 L11.8100935,1.05340467 Z"
                            id="Shape"
                            fill="currentColor"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </cw-icon>
            <span>Affiliates</span>
          </Link>
        </div>
        <div className="nav-item">
          <Link
            to="/rewards"
            className={`nav-item-link ${
              location.pathname === "/rewards" ? "nav-item-active" : ""
            }`}
          >
            <cw-icon class="nav-item-icon">
              <svg
                width="15px"
                height="15px"
                viewBox="0 0 15.5 15.5"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
              >
                <g
                  id="Symbols"
                  stroke="none"
                  strokeWidth="1"
                  fill="none"
                  fillRule="evenodd"
                >
                  <g
                    id="Global-/-Navigation"
                    transform="translate(-681.000000, -27.000000)"
                  >
                    <rect
                      id="Container"
                      fill="none"
                      transform="translate(720.000000, 35.000000) scale(-1, -1) translate(-720.000000, -35.000000) "
                      x="0"
                      y="0"
                      width="1440"
                      height="70"
                    ></rect>
                    <g id="Group-9" transform="translate(661.000000, 0.000000)">
                      <rect
                        id="Container"
                        fill="none"
                        transform="translate(65.500000, 35.000000) scale(-1, -1) translate(-65.500000, -35.000000) "
                        x="0"
                        y="0"
                        width="131"
                        height="70"
                      ></rect>
                      <g id="Gold" transform="translate(20.000000, 27.000000)">
                        <g
                          id="noun_Gift_797120"
                          transform="translate(0.800000, 0.800000)"
                          fill="currentColor"
                          fillRule="nonzero"
                        >
                          <g id="Group">
                            <path
                              d="M13.3333333,3.50877193 L11.3684211,3.50877193 C11.7192982,3.15789474 11.9298246,2.66666667 11.9298246,2.10526316 C11.9298246,0.912280702 11.0175439,0 9.8245614,0 C9.12280702,0 7.85964912,0.631578947 7.01754386,1.47368421 C6.1754386,0.631578947 4.9122807,0 4.21052632,0 C3.01754386,0 2.10526316,0.912280702 2.10526316,2.10526316 C2.10526316,2.66666667 2.31578947,3.15789474 2.66666667,3.50877193 L0.701754386,3.50877193 C0.280701754,3.50877193 0,3.78947368 0,4.21052632 L0,6.1754386 C0,6.24561404 0.0701754386,6.31578947 0.140350877,6.31578947 L6.03508772,6.31578947 C6.1754386,6.31578947 6.31578947,6.1754386 6.31578947,6.03508772 L6.31578947,3.78947368 C6.31578947,3.64912281 6.45614035,3.50877193 6.59649123,3.50877193 L7.43859649,3.50877193 C7.57894737,3.50877193 7.71929825,3.64912281 7.71929825,3.78947368 L7.71929825,6.03508772 C7.71929825,6.1754386 7.85964912,6.31578947 8,6.31578947 L13.8947368,6.31578947 C13.9649123,6.31578947 14.0350877,6.24561404 14.0350877,6.1754386 L14.0350877,4.21052632 C14.0350877,3.78947368 13.754386,3.50877193 13.3333333,3.50877193 Z M4.21052632,2.80701754 C3.78947368,2.80701754 3.50877193,2.52631579 3.50877193,2.10526316 C3.50877193,1.68421053 3.78947368,1.40350877 4.21052632,1.40350877 C4.63157895,1.40350877 5.89473684,2.24561404 6.24561404,2.66666667 C6.03508772,2.73684211 4.21052632,2.80701754 4.21052632,2.80701754 Z M9.8245614,2.80701754 C9.8245614,2.80701754 8,2.73684211 7.78947368,2.66666667 C8.21052632,2.1754386 9.40350877,1.40350877 9.8245614,1.40350877 C10.245614,1.40350877 10.5263158,1.68421053 10.5263158,2.10526316 C10.5263158,2.52631579 10.245614,2.80701754 9.8245614,2.80701754 Z"
                              id="Shape"
                            ></path>
                            <path
                              d="M6.03508772,7.71929825 L0.842105263,7.71929825 C0.771929825,7.71929825 0.701754386,7.78947368 0.701754386,7.85964912 L0.701754386,13.3333333 C0.701754386,13.754386 0.98245614,14.0350877 1.40350877,14.0350877 L6.03508772,14.0350877 C6.1754386,14.0350877 6.31578947,13.8947368 6.31578947,13.754386 L6.31578947,8 C6.31578947,7.85964912 6.1754386,7.71929825 6.03508772,7.71929825 Z"
                              id="Path"
                            ></path>
                            <path
                              d="M13.1929825,7.71929825 L8,7.71929825 C7.85964912,7.71929825 7.71929825,7.85964912 7.71929825,8 L7.71929825,13.754386 C7.71929825,13.8947368 7.85964912,14.0350877 8,14.0350877 L12.6315789,14.0350877 C13.0526316,14.0350877 13.3333333,13.754386 13.3333333,13.3333333 L13.3333333,7.85964912 C13.3333333,7.78947368 13.2631579,7.71929825 13.1929825,7.71929825 Z"
                              id="Path"
                            ></path>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </cw-icon>
            <span>Rewards</span>
          </Link>
        </div>
      </div>
      <div className="header-right">
        <div className="header-withdraw-button">WITHDRAW</div>
        <div className="header-balance-display">
          <img className="open-case-button-coin-image" src={CoinImage} alt="" />
          <span id="header-balance">{tokens?.amount.toFixed(2) ?? 0}</span>
        </div>
        <Link to="/deposit">
          <button className="button header-deposit-button">DEPOSIT</button>
        </Link>
        <div className="header-token-container">
          <img height="21px" src={GemImage} alt="" />
          <span id="header-token-balance">{gems?.amount ?? 0}</span>
        </div>
        <cw-icon class="backpack-icon" onClick={openBackpack}>
          <svg
            width="19"
            height="18"
            viewBox="0 0 19 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="inventory-icon"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M15.3532 7.86113V15.8263C15.3532 16.8324 14.553 17.6479 13.566 17.6479H4.65269C3.66569 17.6479 2.86551 16.8324 2.86551 15.8263V7.86113C3.61114 9.05347 4.91902 9.84665 6.40595 9.84665H7.752V10.2617H10.4667V9.84665H11.8127C13.2996 9.84665 14.6075 9.05347 15.3532 7.86113ZM15.9472 8.74712L17.5685 9.92312V15.711H15.9472V8.74712ZM2.35279 8.74712V15.711H0.73146V9.92312L2.35279 8.74712ZM18.3 6.62565V9.62303L15.9472 7.83986V6.62565H18.3ZM2.35279 6.62565V7.83986L0 9.62303V6.62565H2.35279ZM9.59007 7.61716V9.46248H8.6286V7.61716H9.59007ZM15.6472 0V5.06922C15.6472 5.60131 15.542 6.10808 15.3532 6.5704C14.7756 7.98373 13.4085 8.97772 11.8127 8.97772H10.4667V6.85625H7.752V8.97772H6.40595C4.8102 8.97772 3.44303 7.98373 2.86551 6.5704C2.67663 6.10808 2.57146 5.60131 2.57146 5.06922V0H15.6472Z"
              fill="currentColor"
            ></path>
          </svg>
        </cw-icon>
        <Backpack isOpen={isBackpackOpen} close={closeBackpack} />
        <div className="header-user-container" onClick={openPlayerDropdown}>
          <img
            className={`header-user-avatar ${getLevelTier(
              "border-",
              user.playerLevelInfo.level
            )}`}
            src="https://i.pinimg.com/1200x/0f/8b/9e/0f8b9e3e432c07c82d8b9cbfc5f7e87a.jpg"
            alt=""
          />
          <div
            className={`header-user-avatar-level ${getLevelTier(
              "bg-",
              user.playerLevelInfo.level
            )}`}
          >
            {user.playerLevelInfo.level}
          </div>
          <cw-icon
            _ngcontent-kqr-c356=""
            inlinesvg="assets/icons/chevron-down-solid.svg"
            className="ng-tns-c356-2"
          >
            <svg
              width="19"
              height="19"
              viewBox="0 0 10 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                className={getLevelTier("fill-", user.playerLevelInfo.level)}
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.56551 2.99996C7.56551 3.0885 7.52905 3.17183 7.46655 3.23433L5.13322 5.56767C5.07072 5.63017 4.98739 5.66662 4.89884 5.66662C4.8103 5.66662 4.72697 5.63017 4.66447 5.56767L2.33114 3.23433C2.26864 3.17183 2.23218 3.0885 2.23218 2.99996C2.23218 2.81767 2.38322 2.66663 2.56551 2.66663H7.23218C7.41447 2.66663 7.56551 2.81767 7.56551 2.99996Z"
              ></path>
            </svg>
          </cw-icon>
        </div>
        <NavPlayerDropdown
          isOpen={isPlayerDropdownOpen}
          close={closePlayerDropdown}
        ></NavPlayerDropdown>
      </div>
    </div>
  );
};
