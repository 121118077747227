import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Unboxing.css";
import { BalanceDisplay } from "../../global/Global";
import { Link } from "react-router-dom";

const UnboxingDisplay = ({ caseInfo, index }) => {
  if (
    !caseInfo?.tags?.includes("new") &&
    new Date(caseInfo.created) >=
      new Date(new Date().getTime() - 10 * 24 * 60 * 60 * 1000)
  ) {
    // If created within last 10 days, add [NEW] tag
    if (caseInfo.tags === "") caseInfo.tags += "new";
    else caseInfo.tags += ",new";
  }

  const displayTags = ["new", "hot", "partners", "featured"];
  const tagsArray = caseInfo?.tags
    ?.split(",")
    .filter((tag) => displayTags.includes(tag));

  return (
    <Link to={`/case_opener/${caseInfo.id}`}>
      <div className="unboxing-case-display">
        <div className="unboxing-case-display-upper">
          <div className="unboxing-case-image-container">
            <img
              className="unboxing-case-image"
              src={require(`../../images/cases/${caseInfo.image}`)}
              alt=""
            ></img>
          </div>
          <div className="unboxing-case-display-details">
            <div className="case-tags">
              {tagsArray.map((tag, index) => (
                <div key={index} className={`_${tag.trim()}`} />
              ))}
            </div>
            <div className="unboxing-case-display-name">{caseInfo.name}</div>
            <div className="pretty-balance-display-container">
              <BalanceDisplay balance={caseInfo.cost}></BalanceDisplay>
            </div>
            <button className="unboxing-case-display-button button">
              open case
            </button>
          </div>
        </div>
      </div>
    </Link>
  );
};

const Body = () => {
  const [cases, setCases] = useState([]);
  const [selected, setSelected] = useState("FEATURED");
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    axios
      .get("/cases")
      .then((response) => {
        setCases(
          response.data.filter((caseItem) => {
            return (
              caseItem.levelRequired === 1 && caseItem.tags.includes("csgoroll")
            );
          })
        );
        console.log(response.data);
      })
      .catch((error) => {
        console.error("Failed to fetch cases:", error);
      });
  }, []);

  const handleFilterClick = (text) => {
    setSelected(text);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const filters = ["FEATURED", "NEW", "10%", "5%", "PARTNERS"];

  const filteredCases = cases.filter((caseItem) => {
    return (
      caseItem.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
      /* Don't filter on tag if 'featured' is selected. Idk why, that's what others do */ (selected.toLowerCase() ===
        "featured" ||
        caseItem.tags.toLowerCase().includes(selected.toLowerCase()))
    );
  });

  return (
    <div className="csgoroll-body-container">
      <div className="middle-container">
        <div className="unboxing-container">
          <div className="unboxing-filters-container">
            <div className="unboxingvvvv-filters-upper">
              <div className="unboxing-filter-container">
                {filters.map((text) => (
                  <div
                    key={text}
                    onClick={() => handleFilterClick(text)}
                    className={`unboxing-filter-text ${
                      selected === text ? "selected-unboxing-filter" : ""
                    }`}
                  >
                    {text}
                  </div>
                ))}
                <div className="unboxing-filter-divider"></div>
                <div className="unboxing-filter-search-container">
                  <cw-icon class="unboxing-search-icon">
                    <svg viewBox="0 0 17 17">
                      <path
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M11.9153298,7.23993023 C11.9153298,8.49519316 11.42414,9.67768723 10.5509136,10.5509136 C9.67768723,11.4423322 8.49519316,11.9335221 7.23993023,11.9335221 C5.9846673,11.9335221 4.80217323,11.4423322 3.92894684,10.5509136 C2.1097252,8.73169197 2.1097252,5.7663607 3.92894684,3.92894684 C4.82036545,3.03752824 5.9846673,2.54633839 7.23993023,2.54633839 C8.47700094,2.54633839 9.65949501,3.03752824 10.5509136,3.92894684 C11.42414,4.80217323 11.9153298,5.9846673 11.9153298,7.23993023 M15.7720797,14.6623545 L12.2064053,11.0966801 C14.116588,8.64073089 13.8982814,5.03867204 11.6970232,2.83741386 C10.5145292,1.63672757 8.93180635,1 7.25812245,1 C5.58443854,1 4.00171571,1.65491979 2.81922164,2.83741386 C1.63672757,4.00171571 1,5.58443854 1,7.25812245 C1,8.93180635 1.65491979,10.5145292 2.83741386,11.6970232 C4.00171571,12.8613251 5.58443854,13.5162449 7.25812245,13.5162449 C8.64073089,13.5162449 10.0051471,13.0614395 11.1148723,12.2064053 L14.6623545,15.7720797 C14.8078923,15.9176175 15.0080066,16.0085785 15.208121,16.0085785 C15.4082354,16.0085785 15.6083498,15.9176175 15.7538875,15.7720797 C16.0813474,15.4446198 16.0813474,14.9716222 15.7720797,14.6623545"
                      ></path>
                    </svg>
                  </cw-icon>
                  <input
                    placeholder="Search boxes..."
                    formcontrolname="boxName"
                    autoComplete="off"
                    spellCheck="false"
                    className="unboxing-search-input"
                    value={searchTerm}
                    onChange={handleSearch}
                  />
                </div>
              </div>
            </div>
            <div className="unboxing-filters-lower"></div>
          </div>
          <div className="unboxing-cases-grid">
            {filteredCases.map((caseItem, index) => (
              <UnboxingDisplay caseInfo={caseItem} key={caseItem.id} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export const Unboxing = () => {
  return <Body />;
};
