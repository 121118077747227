import { useAuth } from "../../contexts/AuthContext";
import { Link } from "react-router-dom";
import React from 'react';
import axios from "axios";
import "./BattleCaseDisplay.css";


const BattleCaseDisplay = () => {

    return (
        <div className="battle-case-display">
            <div className="battle-case-display-inner"></div>
        </div>
    );
}

export default BattleCaseDisplay;