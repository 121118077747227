import CoinImage from "../images/coin.svg";
import { Link } from "react-router-dom";
import AvatarPlaceholderImage from "../images/avatar_placeholder.png";
import "./Global.css";
import "./Normalize.css";

export const getLevelTier = (prefix, level) => {
  if ((level >= 1 && level <= 19)) {
    return prefix + "tier-1";
  } else if (level >= 20 && level <= 39) {
    return prefix + "tier-2";
  } else if (level >= 40 && level <= 59) {
    return prefix + "tier-3";
  } else if (level >= 60 && level <= 79) {
    return prefix + "tier-4";
  } else if (level >= 80 && level <= 99) {
    return prefix + "tier-5";
  } else if (level >= 100) {
    return prefix + "tier-6";
  }
  return "";
};

export const getEmpireLevelTier = (prefix, level) => {
  if (level == null || (level >= 1 && level <= 19)) {
    return prefix + "empire-tier-1";
  } else if (level >= 20 && level <= 39) {
    return prefix + "empire-tier-2";
  } else if (level >= 40 && level <= 59) {
    return prefix + "empire-tier-3";
  } else if (level >= 60 && level <= 79) {
    return prefix + "empire-tier-4";
  } else if (level >= 80 && level <= 99) {
    return prefix + "empire-tier-5";
  } else if (level >= 100 && level <= 119) {
    return prefix + "empire-tier-6";
  } else if (level >= 120) {
    return prefix + "empire-tier-7";
  }

  return "";
};

export const getEmpireItemGlowClass = (price) => {
    if (price < 1) {
        return "empire-item-glow-1";
    } else if (price >= 1 && price < 4.99) {
        return "empire-item-glow-2";
    } else if (price >= 5.00 && price < 24.99) {
        return "empire-item-glow-3";
    } else if (price >= 25.00 && price < 99.99) {
        return "empire-item-glow-4";
    } else if (price >= 100.00 && price < 999.99) {
        return "empire-item-glow-5";
    } else if (price >= 1000.00) {
        return "empire-item-glow-6";
    }
    return "";
}

export const BalanceDisplay = ({ balance, displayFree = true }) => {
  return (
    <div className="balance-display-container">
      {(balance !== 0 || !displayFree) && (
        <img className="balance-display-image" src={CoinImage} alt="" />
      )}
      <span className="balance-display-text">
        {balance === 0 && displayFree ? "FREE" : balance?.toFixed(2)}
      </span>
    </div>
  );
};

export const getHighestRank = (roles) => {
    if (roles?.administrator) return 'admin';
    if (roles?.developer) return 'dev';
    if (roles?.superModerator) return 'manager';
    if (roles?.moderator) return 'moderator';
    if (roles?.helper) return 'helper';
    return null; // No rank
};

export const AvatarPlaceholder = () => AvatarPlaceholderImage;

export const AvatarDisplay = ({ imageUrl, level, playerStaffRoles, centered = false }) => {

    if (imageUrl == null && level == null) {
        return (
            <svg width="37" height="37" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g opacity="0.1">
                    <path d="M30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 16.6563 0.268449 18.2499 0.76417 19.7395C1.28527 19.0999 1.92123 18.5576 2.64065 18.1443C2.38555 17.1386 2.25 16.0851 2.25 15C2.25 7.95837 7.95837 2.25 15 2.25C22.0416 2.25 27.75 7.95837 27.75 15C27.75 22.0416 22.0416 27.75 15 27.75C13.9149 27.75 12.8614 27.6144 11.856 27.3594C11.4424 28.0788 10.9001 28.7147 10.2603 29.2358C11.7501 29.7316 13.3437 30 15 30C23.2843 30 30 23.2843 30 15Z" fill="currentColor"></path>
                    <path d="M6 30C9.31371 30 12 27.3137 12 24C12 20.6863 9.31371 18 6 18C2.68629 18 0 20.6863 0 24C0 27.3137 2.68629 30 6 30Z" fill="currentColor"></path>
                    <path fillRule="evenodd" clipRule="evenodd" d="M15 3C21.6274 3 27 8.37258 27 15C27 21.6274 21.6274 27 15 27C14.0361 27 13.0986 26.8863 12.2005 26.6717C12.5542 25.852 12.75 24.9488 12.75 24C12.75 20.2721 9.72792 17.25 6 17.25C5.05116 17.25 4.14804 17.4458 3.32885 17.7991C3.11366 16.9014 3 15.9639 3 15C3 8.37258 8.37258 3 15 3Z" fill="currentColor"></path>
                </g>
            </svg>
        );
    }

  const avatarStyle = {
    backgroundImage: `url(${imageUrl || ''})`,
    };

  return (
    <Link
      to=""
      className={`player-avatar-container ${centered ? "centered" : ""}`}
    >
      <div className="player-avatar">
        <div
          style={avatarStyle}
          className={`player-avatar-image ${getLevelTier("border-", level)}`}
              ></div>
              {level != null ?
                  (<div className={`player-avatar-level ${getLevelTier("bg-", level)}`}>
                      {level}
                  </div>) : (<div className={`player-avatar-level`} style={{ border: "1px solid #272c33"} }>
                  </div>) }
      </div>
    </Link>
  );
};
