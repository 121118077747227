import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "./contexts/AuthContext";
import { Counter } from "./components/Counter";
import { FetchData } from "./components/FetchData";
import { Home } from "./components/Home";
import LoginPage from "./pages/login_register/LoginPage";
import SignUpPage from "./pages/login_register/SignUpPage";
import { CaseOpener } from "./pages/case_opener/CaseOpener";
import { CaseBattlePage } from "./pages/case_battle/CaseBattlePage";
import { EmpireCaseOpener } from "./pages/empire_case_opener/EmpireCaseOpener";
import { Unboxing } from "./pages/unboxing/Unboxing";
import { EmpireUnboxing } from "./pages/empire_unboxing/EmpireUnboxing";
import { DicePage } from "./pages/dice/DicePage";
import { DepositPage } from "./pages/deposit/DepositPage";
import { EmpireDepositPage } from "./pages/empire_deposit/EmpireDepositPage";
import { EmpireCoinflipPage } from "./pages/empire_coinflip/EmpireCoinflipPage";
import { EmpireDicePage } from "./pages/empire_dice/EmpireDicePage";
import { Rewards } from "./pages/rewards/Rewards";
import { Layout } from "./components/Layout";
import { EmpireLayout } from "./empire_components/EmpireLayout/EmpireLayout";
import { CaseBattlesPage } from "./pages/case_battles/CaseBattles";
import { CreateCaseBattlePage } from "./pages/create_case_battle/CreateCaseBattlePage";

export const PrivateRoute = ({ redirectPath = "/login", children }) => {
  const { user } = useAuth();

  if (!user) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? children : <Outlet />;
};

export const PublicRoutes = [
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/signup",
    element: <SignUpPage />,
  },
  {
    path: "/counter",
    element: <Counter />,
  },
  {
    path: "/fetch-data",
    element: <FetchData />,
  },
];

export const PrivateRoutes = [
  {
    index: true,
    element: <Home />,
  },
  {
    path: "/case_opener/:caseId/",
    element: (
      <Layout>
        <CaseOpener />
      </Layout>
    ),
  },
  //{
  //    path: "/empire_case_opener/:caseId/",
  //    element: (
  //        <Layout>
  //            <EmpireCaseOpener />
  //        </Layout>
  //    ),
  //},
  {
    path: "/case-battle/:battleId/",
    element: (
      <Layout>
        <CaseBattlePage />
      </Layout>
    ),
  },
  {
    path: "/unboxing/",
    element: (
      <Layout>
        <Unboxing />
      </Layout>
    ),
  },
  {
    path: "/case-battles/",
    element: (
      <Layout>
        <CaseBattlesPage />
      </Layout>
    ),
  },
  {
    path: "/create-case-battle/",
    element: (
      <Layout>
        <CreateCaseBattlePage />
      </Layout>
    ),
  },
  {
    path: "/dice/",
    element: (
      <Layout>
        <DicePage />
      </Layout>
    ),
  },
  {
    path: "/rewards/",
    element: (
      <Layout>
        <Rewards />
      </Layout>
    ),
  },
  {
    path: "/deposit/",
    element: (
      <Layout>
        <DepositPage />
      </Layout>
    ),
  },
  {
    path: "/empire-unboxing/",
    element: (
      <EmpireLayout>
        <EmpireUnboxing />
      </EmpireLayout>
    ),
  },
  {
    path: "/empire-case-opener/:caseId/",
    element: (
      <EmpireLayout>
        <EmpireCaseOpener />
      </EmpireLayout>
    ),
  },
  {
    path: "/empire-deposit/",
    element: (
      <EmpireLayout>
        <EmpireDepositPage />
      </EmpireLayout>
    ),
    },
    {
        path: "/empire-dice/",
        element: (
            <EmpireLayout>
                <EmpireDicePage />
            </EmpireLayout>
        ),
    },
    {
        path: "/empire-coinflip/",
        element: (
            <EmpireLayout>
                <EmpireCoinflipPage />
            </EmpireLayout>
        ),
    },
];
