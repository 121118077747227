import React, { useState, useEffect } from "react";
import { useAuth } from "../../contexts/AuthContext";
import axios from "axios";
import "./Rewards.css";
import { BalanceDisplay } from "../../global/Global";
import { Link } from "react-router-dom";

const claimCode = async (code) => {
  try {
    let response = await axios.post("/promocodes/claim", { code });

    if (response?.status !== 200) {
      console.error("Error claiming code!");
    }
  } catch (exception) {
    //addError(`Claim Code request returned an error: ${exception}`);
  }
};

function convertDateStringToUTC(date) {
  var t = date.replace("T", " ").split(/[- :]/);

  return new Date(Date.UTC(t[0], t[1] - 1, t[2], t[3], t[4], t[5]));
}

const RewardsCaseDisplay = ({ caseInfo, index }) => {
  const { user } = useAuth();
  const [cooldownTimer, setCooldownTimer] = useState("");

  const isLevelTooLow = user.playerLevelInfo.level < caseInfo.levelRequired;
  const cooldownEndTime = convertDateStringToUTC(
    caseInfo.cooldownEnd
  ).getTime();
  const isOnCooldown = cooldownEndTime > Date.now();

  useEffect(() => {
    let interval;

    if (isOnCooldown) {
      interval = setInterval(() => {
        const nowUTC = Date.now();
        const timeLeft = cooldownEndTime - nowUTC;

        if (timeLeft <= 0) {
          clearInterval(interval);
          setCooldownTimer("");
        } else {
          const hours = Math.floor(timeLeft / (1000 * 60 * 60));
          const minutes = Math.floor(
            (timeLeft % (1000 * 60 * 60)) / (1000 * 60)
          );
          const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);

          setCooldownTimer(
            `open case in ${hours > 0 ? `${hours}H ` : ""}${
              minutes > 0 ? `${minutes}M ` : ""
            }${seconds}S`
          );
        }
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [cooldownEndTime, isOnCooldown]);

  let buttonText = `open case`;
  if (isLevelTooLow) {
    buttonText = `LEVEL ${caseInfo.levelRequired}`;
  } else if (isOnCooldown) {
    buttonText = cooldownTimer || `Calculating...`;
  }

  return (
    <Link to={`/case_opener/${caseInfo.id}`}>
      <div className="unboxing-case-display">
        <div className="unboxing-case-display-upper">
          <div className="unboxing-case-image-container">
            <img
              className="unboxing-case-image"
              src={require(`../../images/cases/${caseInfo.image}`)}
              alt=""
            ></img>
            <img
              className="rewards-case-item-image"
              src={caseInfo.drops[0].item.imageUrl}
              alt=""
            ></img>
          </div>
          <div className="unboxing-case-display-details">
            <div className="unboxing-case-display-name">{caseInfo.name}</div>
            <div className="pretty-balance-display-container">
              <BalanceDisplay balance={caseInfo.cost}></BalanceDisplay>
            </div>
            <button
              className="unboxing-case-display-button button"
              disabled={isLevelTooLow || isOnCooldown}
            >
              {buttonText}
            </button>
          </div>
        </div>
      </div>
    </Link>
  );
};

const Body = () => {
  const [code, setCode] = useState("");
  const [cases, setCases] = useState([]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    await claimCode(code);
  };

  useEffect(() => {
    axios
      .get("/cases")
      .then((response) => {
        setCases(
          response.data
            .filter((caseItem) => {
              return caseItem.levelRequired > 1;
            })
            .sort((a, b) => a.id - b.id)
        );
        console.log(response.data);
      })
      .catch((error) => {
        console.error("Failed to fetch cases:", error);
      });
  }, []);

  return (
    <div className="csgoroll-body-container">
      <div className="middle-container">
        <div className="rewards-page-container">
          <div className="rewards-promo-header">
            <form className="promo-code-container" onSubmit={handleSubmit}>
              <div className="promo-code-titles">
                <div className="promo-code-header">USE PROMO CODE</div>
                <div className="promo-code-subheader">
                  Don't have a promo code? Use "
                  <span style={{ color: "#00c74d" }}>VARIANCEWARREN</span>".
                </div>
              </div>
              <div className="rewards-promo-code-input-wrapper">
                <div className="rewards-promo-code-input-description">
                  PROMO CODE
                </div>
                <div className="rewards-promo-code-input-container">
                  <input
                    className="rewards-promo-code-input"
                    spellCheck="false"
                    placeholder="Enter code..."
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                  />
                </div>
              </div>
              <button className="button" type="submit">
                Claim
              </button>
            </form>
          </div>
          <div className="unboxing-cases-grid">
            {cases.map((caseItem, index) => (
              <RewardsCaseDisplay caseInfo={caseItem} key={caseItem.id} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export const Rewards = () => {
  return <Body />;
};
