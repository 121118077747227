import React, { useState } from "react";
import CoinImage from "../../images/coin.svg";
import "./BalanceInput.css";

export const BalanceInput = ({ displayIcon = true, prefix = '', multiplier = 1, onButtonClick }) => {
    const [value, setValue] = useState('');
    const maxValue = 100000;  // Maximum value

    const formatNumber = (num) => {
        let parts = num.split('.');
        parts[0] = parts[0].replace(/[^0-9]/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        if (parts[1]) {
            parts[1] = parts[1].slice(0, 2); // Allow up to two decimal places
        }
        return parts.join('.');
    };

    const handleChange = (event) => {
        const rawValue = event.target.value.replace(/,/g, '');
        const numericValue = rawValue.includes('.') ? parseFloat(rawValue) : parseInt(rawValue, 10) || 0;
        if (numericValue > maxValue) {
            return;
        }
        const formattedNumber = formatNumber(event.target.value);
        setValue(formattedNumber);
    };

    const calculateButtonAmount = () => {
        const numericValue = parseFloat(value.replace(/,/g, '')) || 0;
        return (numericValue * multiplier).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    };

    const handleButtonClick = () => {
        const numericValue = parseFloat(value.replace(/,/g, '')) || 0;
        const amountToDeposit = numericValue;
        onButtonClick(amountToDeposit);
    };

    return (
        <div className="balance-input-form">
            <div className="balance-input-form-inner">
                {displayIcon && <img className="open-case-button-coin-image" src={CoinImage} alt="" />}
                <input type="text" value={value} onChange={handleChange} />
            </div>
            <button onClick={handleButtonClick} className="button">{`${prefix}${calculateButtonAmount()}`}</button>
        </div>
    );
};
