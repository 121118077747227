import "./CaseBattle.css";
import { BalanceDisplay, AvatarDisplay } from "../../global/Global";
import BattleCaseDislpay from "../../components/battle_case_display/BattleCaseDisplay";
import CoinImage from "../../images/coin.svg";
import { useCallback, useEffect, useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useAuth } from "../../contexts/AuthContext";
import { Button } from "reactstrap";
import { useSignalR } from "../../contexts/SignalRContext";
const { v4: uuidv4 } = require("uuid");

const PlayerCard = ({ player, position }) => {
    console.log("player " + position);
    console.log(player);
  return (
    <div className={`case-battle-body-player-card ${position}`}>
      <div className="case-battle-body-player-card-header">
        <AvatarDisplay level={player?.level} imageUrl={player?.imageUrl} centered={true} />

        <div className="case-battle-body-player-info">
          <div className="case-battle-body-player-info-header">
            {player?.username ?? "Waiting for opponent..."}
          </div>
          <div className="case-battle-body-player-info-footer">
            <BalanceDisplay balance={0.0} displayFree={false} />
          </div>
        </div>
      </div>
      <div className="case-battle-body-player-card-footer">
        {player != null ? (
          "Ready"
        ) : (
          <button className="button">Call A Bot</button>
        )}
      </div>
    </div>
  );
};

const CaseBattleContainer = ({ battleInfo }) => {
  const signalRConnection = useSignalR();
  const { user } = useAuth();
  //   useEffect(() => {
  //     if (signalRConnection) {
  //       signalRConnection.on("ReceiveMessage", handleMessage);
  //     }

  //     return () => {
  //       if (signalRConnection) {
  //         signalRConnection.off("ReceiveMessage", handleMessage);
  //       }
  //     };
  //   }, [signalRConnection]);

  useEffect(() => {
    if (signalRConnection) {
      signalRConnection.on("PlayerJoinedBattle", handleJoinBattle);
    }

    return () => {
      if (signalRConnection) {
        signalRConnection.off("PlayerJoinedBattle", handleJoinBattle);
      }
    };
  }, [signalRConnection]);

  const joinBattle = () => {
    //...
  };

  const handleJoinBattle = (data) => {
    console.log(JSON.stringify(data));
  };

  return (
    <div className="case-battle-container">
      <div className="case-battle-header">
        <div className="case-battle-header-left">
          <div className="case-battle-header-left-upper">
            Total Battle Value
          </div>
          <div className="case-battle-header-left-lower">
            <div className="pretty-balance-display-container">
              <BalanceDisplay balance={battleInfo.cost} />
            </div>
          </div>
        </div>
        <div className="case-battle-header-middle">
          <div className="case-battle-header-middle-counter-upper">
            BOX {battleInfo?.currentRound} OF {battleInfo.cases?.length}
          </div>
          <div className="case-battle-header-middle-counter-lower">
            {battleInfo?.cases?.map((x, index) => {
                return (
                    <div className={"dot" + (index + 1 === battleInfo?.currentRound ? " active" : "" )}></div>
                );
            })}
          </div>
          <Button onClick={joinBattle}>[Debug] Join Battle</Button>
        </div>
        <div className="case-battle-header-right">
          <div
            className="case-battle-header-right-upper"
            onClick={() => alert("LMAO You thought! SIKE")}
          >
            <cw-icon style={{ width: "1.2em", height: "1.2em" }}>
              <svg
                width="68"
                height="85"
                viewBox="0 0 68 85"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M34 0.5L0 15.7727V38.6818C0 59.8727 14.5067 79.6891 34 84.5C53.4933 79.6891 68 59.8727 68 38.6818V15.7727L34 0.5Z"
                  fill="#00C74D"
                ></path>
                <path
                  d="M11.334 46.3176L26.4451 61.5903L56.6673 31.0449L51.3407 25.623L26.4451 50.7849L16.6607 40.934L11.334 46.3176Z"
                  fill="white"
                ></path>
              </svg>
            </cw-icon>
            Provably Fair
          </div>
          <div className="case-battle-header-right-lower">
            <div className="case-battle-header-emotes">
              <div className="case-battle-header-emote">🤬</div>
              <div className="case-battle-header-emote">😭</div>
              <div className="case-battle-header-emote">🤯</div>
              <div className="case-battle-header-emote">😱</div>
              <div className="case-battle-header-emote">🔥</div>
              <div className="case-battle-header-emote">🤑</div>
              <div className="case-battle-header-emote">🥳</div>
              <div className="case-battle-header-emote">🍀</div>
            </div>
          </div>
        </div>
      </div>
      <div className="case-battle-body-header">
        <div className="case-battle-body-header-left">
          <cw-icon
            style={{ width: "1.2em" }}
            inlinesvg="assets/icons/link.svg"
            class="mr-1"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.7583 9.24186C17.0922 11.5781 17.0601 15.3238 14.7724 17.6243C14.7681 17.629 14.763 17.634 14.7583 17.6387L12.1333 20.2637C9.81807 22.579 6.05131 22.5787 3.73643 20.2637C1.42119 17.9489 1.42119 14.1817 3.73643 11.8669L5.18588 10.4174C5.57026 10.033 6.23221 10.2885 6.25205 10.8317C6.27736 11.524 6.40151 12.2195 6.63057 12.8911C6.70815 13.1185 6.65272 13.3701 6.48279 13.54L5.97158 14.0512C4.87682 15.146 4.84248 16.9286 5.92647 18.034C7.02115 19.1504 8.82045 19.1571 9.92354 18.054L12.5485 15.4294C13.6497 14.3281 13.6451 12.5482 12.5485 11.4516C12.404 11.3073 12.2583 11.1952 12.1446 11.1169C12.0641 11.0616 11.9977 10.9883 11.9506 10.9028C11.9034 10.8173 11.877 10.722 11.8732 10.6245C11.8578 10.2117 12.004 9.78635 12.3302 9.46018L13.1526 8.63772C13.3683 8.42205 13.7066 8.39557 13.9567 8.5701C14.2431 8.77008 14.5113 8.99487 14.7583 9.24186ZM20.2636 3.73631C17.9487 1.42139 14.1819 1.42107 11.8667 3.73631L9.2417 6.36131C9.23701 6.366 9.23194 6.37107 9.22764 6.37576C6.9399 8.67623 6.90783 12.4219 9.2417 14.7582C9.48869 15.0052 9.75692 15.2299 10.0433 15.4299C10.2934 15.6044 10.6317 15.5779 10.8474 15.3623L11.6698 14.5398C11.996 14.2137 12.1422 13.7883 12.1267 13.3755C12.123 13.278 12.0965 13.1827 12.0494 13.0972C12.0023 13.0117 11.9358 12.9384 11.8554 12.8831C11.7416 12.8048 11.596 12.6927 11.4514 12.5484C10.3548 11.4518 10.3502 9.67186 11.4514 8.57065L14.0764 5.94604C15.1795 4.84295 16.9788 4.84959 18.0735 5.96596C19.1575 7.07143 19.1232 8.85401 18.0284 9.94877L17.5172 10.46C17.3473 10.6299 17.2918 10.8815 17.3694 11.1089C17.5985 11.7805 17.7226 12.476 17.7479 13.1683C17.7678 13.7115 18.4297 13.967 18.8141 13.5826L20.2635 12.1331C22.5788 9.81834 22.5788 6.05115 20.2636 3.73631Z"
                fill="currentColor"
              ></path>
            </svg>
          </cw-icon>
          Share Duel
        </div>
        <div className="case-battle-body-header-right">
          <cw-icon
            style={{ width: "1.2em" }}
            inlinesvg="assets/icons/cancel-hexagon.svg"
            class="mr-1"
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.51339 2L2.00006 5.51333V10.4867L5.51339 14H10.4867C11.6667 12.8267 14.0001 10.4867 14.0001 10.4867V5.51333L10.4867 2M6.06673 3.33333H9.93339L12.6667 6.06667V9.93333L9.93339 12.6667H6.06673L3.33339 9.93333V6.06667M6.08006 5.14L5.14006 6.08L7.06006 8L5.14006 9.92L6.08006 10.86L8.00006 8.94L9.92006 10.86L10.8601 9.92L8.94006 8L10.8601 6.08L9.92006 5.14L8.00006 7.06"
                fill="#DE4C41"
              ></path>
            </svg>
          </cw-icon>
          Cancel Duel
        </div>
      </div>
      <div className="case-battle-body">
        <div className="vertical-separator"></div>
        <div className="case-battle-body-inner">
          <PlayerCard player={battleInfo?.players?.find(x => x.slot === 1) || null} position={"tl"} />
          <PlayerCard player={battleInfo?.players?.find(x => x.slot === 2) || null} position={"bl"} />
          <PlayerCard player={battleInfo?.players?.find(x => x.slot === 3) || null} position={"tr"} />
          <PlayerCard player={battleInfo?.players?.find(x => x.slot === 4) || null} position={"br"} />
          <div className="case-battle-body-inner-center-wrapper">
            <BattleCaseDislpay />
          </div>
        </div>
      </div>
    </div>
  );
};

const Body = () => {
  const [battleInfo, setBattleInfo] = useState({
    name: "Loading",
  }); // TODO: Replace with placeholder image
  const { battleId } = useParams();

  useEffect(() => {
    axios
      .get(`/battles/${battleId}`)
      .then((response) => {
        setBattleInfo(response.data.value);
      })
      .catch((error) => {
        console.error("Failed to fetch battle info:", error);
      });
  }, [battleId]);

  return (
    <div className="body-container">
      <div className="middle-container">
        <CaseBattleContainer battleInfo={battleInfo} />
      </div>
    </div>
  );
};

export const CaseBattlePage = () => {
  return <Body />;
};
