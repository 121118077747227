import { NavMenu } from "../nav_menu/NavMenu";
import { Chat } from "../chat/Chat";
import { SignalRProvider } from "../../contexts/SignalRContext";
import { GlobalUpdates } from "../../components/GlobalUpdates";
import "./EmpireLayout.css";

export const EmpireLayout = ({ children }) => {
  return (
    <SignalRProvider>
      <GlobalUpdates>
        <div className="layout-container">
          <NavMenu />
          <Chat />
          {children}
        </div>
      </GlobalUpdates>
    </SignalRProvider>
  );
};
