import axios from "axios";
import { useState } from "react";
import { useAuth } from "../contexts/AuthContext";

export const useLogin = () => {
  const { setUser } = useAuth();
  const [loading, setIsLoading] = useState(false);

  const login = async (email, password) => {
    try {
      setIsLoading(true);

      const response = await axios.post("/users/login", {
        email: email,
        password: password,
      });

      const userResponse = await axios.get("/me");
      setUser(userResponse.data);
    } finally {
      setIsLoading(false);
    }
  };

  return { login, loading };
};

export const useRegister = () => {
  const { setUser } = useAuth();
  const [loading, setIsLoading] = useState(false);

  const register = async (username, email, password) => {
    try {
      setIsLoading(true);

      const response = await axios.post("/users/register", {
        username,
        email,
        password,
      });

      if (response?.status !== 200) {
        console.log("Something went wrong during register in: ", response);
        return;
      }

      const userResponse = await axios.get("/me");
      setUser(userResponse.data);
    } finally {
      setIsLoading(false);
    }
  };

  return { register, loading };
};
