import { useEffect } from "react";
import { useAuth } from "../contexts/AuthContext";
import { useSignalR } from "../contexts/SignalRContext";

export const GlobalUpdates = ({ children }) => {
  const { setUser } = useAuth();
  const signalRConnection = useSignalR();

  useEffect(() => {
    if (signalRConnection) {
      signalRConnection.on("UpdatePlayerLevelInfo", handleUserLevelInfoUpdate);
    }

    return () => {
      if (signalRConnection) {
        signalRConnection.off(
          "UpdatePlayerLevelInfo",
          handleUserLevelInfoUpdate
        );
      }
    };
  }, [signalRConnection]);

  const handleUserLevelInfoUpdate = (newLevelInfo) => {
    setUser((user) => ({
      ...user,
      playerLevelInfo: newLevelInfo,
    }));
  };

  return <>{children}</>;
};
