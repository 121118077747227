import "./EmpireCoinflipPage.css";
import CoinImage from "../../images/coin.svg";
import { useCallback, useEffect, useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { getEmpireItemGlowClass } from "../../global/Global";
import { useAuth } from "../../contexts/AuthContext";
import { Link } from "react-router-dom";
import spinEndSound from "../../sounds/spin_end.mp3";
const { v4: uuidv4 } = require("uuid");

const BetInputControls = ({ setBetAmount, currentAmount }) => {
    const { user, setUser } = useAuth();

    const tokens = user.balances?.find((x) => x.currency === 1);
    
    const handleIncrement = (increment) => {
        setBetAmount(prevAmount => prevAmount + increment);
    };

    const handleSetAmount = (amount) => {
        setBetAmount(amount);
    };

    return (
        <div className="empire-coinflip-bet-input-controls">
            <div className="empire-coinflip-bet-input-button-container" onClick={() => handleSetAmount(0)}>
                <button className="empire-coinflip-bet-input-button">CLEAR</button>
            </div>
            <div className="empire-coinflip-bet-input-button-container" onClick={() => handleIncrement(1)}>
                <button className="empire-coinflip-bet-input-button">+1</button>
            </div>
            <div className="empire-coinflip-bet-input-button-container" onClick={() => handleIncrement(10)}>
                <button className="empire-coinflip-bet-input-button">+10</button>
            </div>
            <div className="empire-coinflip-bet-input-button-container" onClick={() => handleIncrement(100)}>
                <button className="empire-coinflip-bet-input-button">+100</button>
            </div>
            <div className="empire-coinflip-bet-input-button-container" onClick={() => handleSetAmount(currentAmount / 2)}>
                <button className="empire-coinflip-bet-input-button">1/2</button>
            </div>
            <div className="empire-coinflip-bet-input-button-container" onClick={() => handleSetAmount(currentAmount * 2)}>
                <button className="empire-coinflip-bet-input-button">X2</button>
            </div>
            <div className="empire-coinflip-bet-input-button-container" onClick={() => handleSetAmount(tokens.amount)}>
                <button className="empire-coinflip-bet-input-button">MAX</button>
            </div>
        </div>
    );
}

const GameCountSelector = () => {
    const [gameCountSelectorOpen, setGameCountSelectorOpen] = useState(false);
    const [gameCount, setGameCount] = useState(1);
    

    const updateGameCount = (gameCount) => {


    };

    const updateSelectedSide = (side) => {


    };

    return (
        <div className={`empire-coinflip-bet-count-selector-container ${gameCountSelectorOpen ? "open" : ""}`}>
            <button className="empire-coinflip-bet-count-selector">
                1x
                <svg data-v-7c9a4b10="" xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="h-full w-full fill-current"><path fillRule="evenodd" d="M7 8.47 2.566 4.037a.8.8 0 1 0-1.132 1.132l4.647 4.646a1.3 1.3 0 0 0 1.838 0l4.647-4.646a.8.8 0 1 0-1.132-1.132L7 8.471Z" clipRule="evenodd"></path></svg>
            </button>
            {gameCountSelectorOpen ?
                <ul>
                    <li>1 game</li>
                    <li>2 games</li>
                    <li>3 games</li>
                    <li>5 games</li>
                    <li>10 games</li>
                </ul>
                : ""
                }
        </div>
    );
}

const CoinflipPage = () => {
    
    const [betAmount, setBetAmount] = useState(0);
    const [selectedSide, setSelectedSide] = useState("");
    const [currentOrHistory, setCurrentOrHistory] = useState("current");

    const formatNumber = (number) => {
        return number.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
    };

    return (
        <div className="empire-coinflip">
            <div className="empire-coinflip-header">
                <div className="empire-coinflip-title">Coinflip</div>
                <div className="empire-coinflip-side-container">
                    Side:
                    <div className={`empire-coinflip-side-container-side-ct ${selectedSide === "ct" ? "selected" : ""}`} onClick={() => setSelectedSide("ct")}></div>
                    <div className={`empire-coinflip-side-container-side-t ${selectedSide === "t" ? "selected" : ""}`} onClick={() => setSelectedSide("t")}></div>
                </div>
                <div className="empire-coinflip-amount-container">
                    <div className="empire-coinflip-bet-input-container">
                        <span className="empire-coinflip-bet-input-container-span">
                            <svg data-v-e3b21148="" viewBox="0 0 22 22" class="w-18 h-18 fill-current text-yellow-2  my-auto"><path d="M21.72 4a.86.86 0 0 0 0-.17c0-1.7-4.44-3.09-9.93-3.09S1.9 2.14 1.9 3.84a1.46 1.46 0 0 0 0 .22.86.86 0 0 1-.04-.06v2.55c0 .55.61 1.15 1.68 1.7 0 0 2.63 1.58 8.26 1.64a21.28 21.28 0 0 0 7-1.09c1.82-.64 2.91-1.46 2.91-2.19V4.05a.28.28 0 0 0 0-.09.08.08 0 0 1 .01.04ZM4.37 3.31c.73-1 3.78-1.71 7.43-1.71s6.78.75 7.46 1.74a.72.72 0 0 1 .14.41s0 .38 0 .41a2.55 2.55 0 0 0-.4-.32c-1.07-.76-3.9-1.2-7.18-1.2s-6 .4-7.1 1.14a1.73 1.73 0 0 0-.49.38v-.41a.77.77 0 0 1 .14-.44Zm15.52 6.46v2.55c0 .73-1.08 1.55-2.91 2.2a21 21 0 0 1-7 1.08c-5.67-.06-8.3-1.6-8.3-1.6C.61 13.42 0 12.82 0 12.27v-2.5a.28.28 0 0 1 0-.09.76.76 0 0 1 0-.16C0 9 .57 8.38 1.55 7.9V8c0 .58.67 1.21 1.85 1.78 0 0 2.58 1.38 8.1 1.55a19.56 19.56 0 0 0 7-1.08 10.48 10.48 0 0 0 1.32-.56 1 1 0 0 1-.08.28 1.59 1.59 0 0 0 .12-.27ZM22 15.41v2.5c0 .55-.61 1.15-1.68 1.7 0 0-2.63 1.57-8.27 1.63a21 21 0 0 1-7-1.08C3.19 19.52 2.11 18.7 2.11 18v-2.68a1.89 1.89 0 0 0 .09.21 10.35 10.35 0 0 0 1.23.52 19.78 19.78 0 0 0 7 1.08c5.52-.18 8.1-1.55 8.1-1.55 1.17-.57 1.85-1.2 1.85-1.78v-.08c1 .48 1.54 1 1.54 1.65v-.06a.28.28 0 0 1 .08.1Z"></path></svg>
                        </span>
                        <input
                            class="empire-coinflip-bet-input"
                            type="text" placeholder="Amount..."
                            inputmode="decimal"
                            autocomplete="off"
                            value={formatNumber(betAmount)}
                            onChange={(e) => setBetAmount(parseFloat(e.target.value.replace(/,/g, '')) || 0)}></input>
                    </div>
                    <div className="empire-coinflip-bet-input-controls-container">
                        <BetInputControls setBetAmount={setBetAmount} currentAmount={betAmount}></BetInputControls>
                    </div>
                    <GameCountSelector></GameCountSelector>
                    <div></div>
                </div>
                <div className="empire-coinflip-amount-container"></div>
                <button class="empire-create-coinflip-button empire-button">Create game</button>
            </div>
            <div className="empire-coinflip-own-games-container">
                <div className="empire-coinflip-own-games-header">
                    <div className="empire-coinflip-own-games-header-left">
                        <span>My games</span>
                        <div>0</div>
                    </div>
                    <div className="empire-coinflip-own-games-header-right">
                        <button class={`empire-coinflip-own-games-header-right-button ${currentOrHistory === "current" ? "selected" : ""}`} onClick={() => setCurrentOrHistory("current")}>Current</button>
                        <button class={`empire-coinflip-own-games-header-right-button ${currentOrHistory === "history" ? "selected" : ""}`} onClick={() => setCurrentOrHistory("history")}>History</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

const Body = () => {


    return (
        <div className="empire-body-container">
            <CoinflipPage></CoinflipPage>
        </div>
    );
};

export const EmpireCoinflipPage = () => {
    return <Body />;
};
