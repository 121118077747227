import "./CreateCaseBattle.css";
import { BalanceDisplay } from "../../global/Global";
import CaseSelector from "../../components/case_selector/CaseSelector";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import axios from "axios";

const CaseDisplay = ({ caseInfo, selectedCases, onAddCase, onRemoveCase }) => {
  const matchingCase = selectedCases.find((sc) => sc.id === caseInfo.id);

  return (
    <div className="case-selector-case-display">
      <div className="case-selector-case-display-upper">
        <div className="case-selector-case-image-container">
          <img
            className="case-selector-case-image"
            src={require(`../../images/cases/${caseInfo.image}`)}
            alt=""
          ></img>
        </div>
        <div className="case-selector-case-display-details">
          <div className="case-selector-case-display-name">{caseInfo.name}</div>
          <BalanceDisplay balance={caseInfo.cost}></BalanceDisplay>
        </div>
      </div>
      {matchingCase ? (
        <div className="case-selector-case-display-counter-container">
          <div
            className="case-selector-case-display-counter-button"
            onClick={onRemoveCase}
          >
            -
          </div>
          <div className="case-selector-case-display-counter-inner">
            {matchingCase.amount}
          </div>
          <div
            className="case-selector-case-display-counter-button"
            onClick={onAddCase}
          >
            +
          </div>
        </div>
      ) : (
        <button
          className="case-selector-case-display-button button"
          onClick={onAddCase}
        >
          Add
        </button>
      )}
    </div>
  );
};

const CreateCaseBattleBody = () => {
  const [isCaseSelectorOpen, setCaseSelectorOpen] = useState(false);
  const [selectedCases, setSelectedCases] = useState([]);
   const [errors, setErrors] = useState([]);
  const { user, setUser } = useAuth();

  const navigate = useNavigate();

  const openCaseSelector = () => {
    setCaseSelectorOpen(true);
  };

  const closeCaseSelector = () => {
    setCaseSelectorOpen(false);
  };

  const addError = (message) => {
    const id = Date.now(); // Unique identifier
    setErrors((prevErrors) => [...prevErrors, { id, message }]);
    setTimeout(() => {
      setErrors((prevErrors) => prevErrors.filter((error) => error.id !== id));
    }, 5000);
  };

  const CreateCaseBattle = async () => {
    try {
      const caseAmounts = selectedCases.reduce((acc, caseItem) => {
        acc[caseItem.id] = caseItem.amount;
        return acc;
      }, {});

      let response = await axios.post("/battles/create", {
        Type: "CASE_BATTLE",
        Mode: "NORMAL",
        Teams: "2",
        MinPlayers: "4",
        MaxPlayers: "4",
        Currency: 1,
        CaseAmounts: caseAmounts,
      });

      if (response?.status !== 200) {
        addError(response?.data);
        return;
        }

        const totalCost = selectedCases.reduce((total, caseItem) => {
            return total + caseItem.cost * caseItem.amount;
        }, 0);

        const newBalances = user.balances.map((balance) => {
            if (balance.currency === 1) { //TODO: Correct currency, and move to WS handling of balances
                balance.amount -= totalCost;
            }

        return balance;
      });

      setUser((user) => ({ ...user, balances: newBalances }));
      navigate(`/case-battle/${response.data.gameId.value}`);
    } catch (exception) {
      const { code, message } = exception.response?.data;
      addError(`${message} (${code})`);
    }
  };

  const handleAddCase = (caseToAdd) => {
    const existingCaseIndex = selectedCases.findIndex(
      (sc) => sc.id === caseToAdd.id
    );

    if (existingCaseIndex !== -1) {
      const updatedSelectedCases = [...selectedCases];
      updatedSelectedCases[existingCaseIndex] = {
        ...updatedSelectedCases[existingCaseIndex],
        amount: updatedSelectedCases[existingCaseIndex].amount + 1,
      };
      setSelectedCases(updatedSelectedCases);
    } else {
      setSelectedCases([...selectedCases, { ...caseToAdd, amount: 1 }]);
    }
  };

  const handleRemoveCase = (caseToRemove) => {
    const existingCaseIndex = selectedCases.findIndex(
      (sc) => sc.id === caseToRemove.id
    );

    if (
      existingCaseIndex !== -1 &&
      selectedCases[existingCaseIndex].amount > 1
    ) {
      const updatedSelectedCases = [...selectedCases];
      updatedSelectedCases[existingCaseIndex] = {
        ...updatedSelectedCases[existingCaseIndex],
        amount: updatedSelectedCases[existingCaseIndex].amount - 1,
      };
      setSelectedCases(updatedSelectedCases);
    } else if (existingCaseIndex !== -1) {
      setSelectedCases(
        selectedCases.filter((_, index) => index !== existingCaseIndex)
      );
    }
  };

  const totalBoxesAdded = selectedCases.reduce(
    (total, sc) => total + sc.amount,
    0
  );

  const totalCost = selectedCases.reduce((total, caseItem) => {
    return total + caseItem.cost * caseItem.amount;
  }, 0);

  return (
    <div className="body-container">
      <div className="middle-container">
        <div className="create-case-battle-container">
          <div className="create-case-battle-header">
            <div className="create-case-battle-header-upper">
              <div className="create-case-battle-header-upper-inner">
                <div className="create-case-battle-title">
                  Create Case Battle
                </div>
                <button
                  className="button create-case-battle-create-button"
                  disabled={totalBoxesAdded === 0}
                  onClick={() => CreateCaseBattle()}
                >
                  Create Duel
                </button>
              </div>
              <div className="create-case-battle-header-upper-info">
                <span>
                  <span
                    className="create-case-battle-header-upper-info-color"
                    style={{ marginRight: "5px" }}
                  >
                    Total Boxes:
                  </span>{" "}
                  {totalBoxesAdded}
                </span>
                <div className="create-case-battle-header-upper-info-cost">
                  <span className="create-case-battle-header-upper-info-color">
                    Total Value:{" "}
                  </span>
                  <BalanceDisplay
                    balance={totalCost}
                    displayFree={false}
                  ></BalanceDisplay>
                </div>
              </div>
            </div>
            <div className="create-case-battle-header-lower"></div>
          </div>
          <div className="create-case-battle-body">
            <div
              className="create-case-battle-add-box create-case-battle-body-hoverable"
              onClick={openCaseSelector}
            >
              <span className="create-case-battle-add-box-plus">
                <cw-icon
                  _ngcontent-ng-c570842978=""
                  inlinesvg="assets/icons/plus-fat.svg"
                >
                  <svg
                    width="10"
                    height="10"
                    viewBox="-2 -1 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M6.5 4.5V0H4.5V4.5H0V6.5H4.5V11H6.5V6.5H11V4.5H6.5Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </cw-icon>
              </span>
              <span className="create-case-battle-add-box-text">Add a Box</span>
            </div>
            {selectedCases.map((caseItem, index) => (
              <CaseDisplay
                caseInfo={caseItem}
                key={caseItem.id}
                selectedCases={selectedCases}
                onAddCase={() => handleAddCase(caseItem)}
                onRemoveCase={() => handleRemoveCase(caseItem)}
              />
            ))}
            <CaseSelector
              isOpen={isCaseSelectorOpen}
              close={closeCaseSelector}
              selectedCases={selectedCases}
              setSelectedCases={setSelectedCases}
            ></CaseSelector>
          </div>
        </div>
      </div>
      {/* Error messages */}
      <div className="error-container">
        {errors.map((error) => (
          <div key={error.id} className="error-box">
            {error.message}
          </div>
        ))}
      </div>
    </div>
  );
};

export const CreateCaseBattlePage = () => {
  return <CreateCaseBattleBody />;
};
