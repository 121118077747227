import React from "react";
import { Link } from "react-router-dom";
import "./Home.css";
import CsgoRollLogo from "../images/logo.png";
import EmpireLogo from "../images/logo_empire.png";
import DuelsBetLogo from "../images/logo_duelsbet.png";

export const Home = () => {
  return (
    <div className="home-container">
      <Link to="/unboxing">
        <img src={CsgoRollLogo} alt=""></img>
      </Link>
      {/*<Link to="/duelsbet-unboxing">*/}
      {/*    <img src={DuelsBetLogo} alt=""></img>*/}
      {/*</Link>*/}
      <Link to="/empire-unboxing">
        <img src={EmpireLogo} alt=""></img>
      </Link>
    </div>
  );
};
