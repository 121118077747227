import React from 'react';
import { BalanceInput } from "../../components/balance_input/BalanceInput"
import axios from 'axios';
import "./DepositPage.css";
import { useAuth } from "../../contexts/AuthContext";

const Body = () => {
    const { user, setUser } = useAuth();

    const DepositBalance = async (amount) => {
        try {
            let response = await axios.post("/admin/showmethemoney",
                {},
                {
                    params: {
                        amount
                    }
                });
            let newBalance = user.balances.map((balance) => {
                if (balance.currency === 1) {
                    balance.amount += amount;
                }
                return balance;
            });

            setUser({ ...user, balances: newBalance });
            console.log("/admin/showmethemoney response:", response);
        } catch (exception) {
            console.error("Error during deposit:", exception);
        }
    };

    return (
        <div className="body-container">
            <div className="middle-container">
                <div className="deposit-page-container">
                    <div className="deposit-page-header">Deposit Balance</div>
                    <div className="deposit-page-subheader">Please enter the amount of balance you would like to be credited.</div>
                    <div className="deposit-page-credit-container">
                        <BalanceInput onButtonClick={ DepositBalance } prefix="Buy for $" multiplier={0.7}></BalanceInput>
                    </div>
                </div>
            </div>
        </div>
    );

};

export const DepositPage = () => {
    return <Body />;
};