import axios from "axios";
import { useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { Toast } from "../../components/Toast";
import { useAuth } from "../../contexts/AuthContext";
import { useRegister } from "../../services/AuthService";
import LogoImage from "../../images/logo.png";
import "./Login.css";

const SignUpPage = () => {
  const { user } = useAuth();
  const { register, loading } = useRegister();

  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState("");

  const signUp = async (event) => {
    try {
      event.preventDefault();

      if (!username || !email || !password) {
        return;
      }

      await register(username, email, password);
    } catch (err) {
      console.error(`Error during register: ${err}`);
      const { code, message } = err.response?.data;
      setIsSuccess(false);
      setError(`${message} (${code})`);
    }
  };

  if (user) {
    return <Navigate to="/" />;
  }

  return (
    <div className="login-page-container">
      <img class="login-page-logo" src={LogoImage} alt="" />
      {error ? (
        <marquee
          className="login-error"
          style={{ width: "auto", textAlign: "center" }}
        >
          {error}
          <p>
            <h3>fix m-me pws uwu senpai</h3>
            <h3>(3O.o)3</h3>
          </p>
        </marquee>
      ) : (
        <></>
      )}
      <form className="login-form" onSubmit={signUp}>
        <input
          type="text"
          className="input"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          autoFocus
          autoComplete="true"
          required
        ></input>
        <input
          className="input"
          type="email"
          placeholder="Email Address"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          autoComplete="true"
          required
        />
        <input
          type="password"
          className="input"
          placeholder="Password"
          onChange={(e) => setPassword(e.target.value)}
          minLength="6"
          required
        ></input>
        <button type="submit" className="button">
          Sign Up
        </button>
        <div>
          Already have an account?
          <Link to="/login" className="login-reg-link">
            Login
          </Link>
        </div>
      </form>
    </div>
  );
};

export default SignUpPage;
