import React, { useState, useEffect } from "react";
import axios from "axios";
import "./EmpireUnboxing.css";
import { BalanceDisplay } from "../../global/Global";
import { Link } from "react-router-dom";

const UnboxingDisplay = ({ caseInfo, index }) => {
  return (
    <Link
      className="unboxing-case-display"
      to={`/empire-case-opener/${caseInfo.id}`}
    >
      <div className="unboxing-case-display-inner">
        <img
          className="unboxing-case-image"
          src={require(`../../images/empire/cases/${caseInfo.image}`)}
          alt=""
        ></img>
        <div className="unboxing-case-display-name">{caseInfo.name}</div>
        <div className="unboxing-case-display-price-container">
          <div className="unboxing-case-display-price nohover">
            <svg
              data-v-5aeb787d=""
              viewBox="0 0 22 22"
              class="w-14 h-14 fill-current text-yellow-2 mr-sm my-auto"
            >
              <path d="M21.72 4a.86.86 0 0 0 0-.17c0-1.7-4.44-3.09-9.93-3.09S1.9 2.14 1.9 3.84a1.46 1.46 0 0 0 0 .22.86.86 0 0 1-.04-.06v2.55c0 .55.61 1.15 1.68 1.7 0 0 2.63 1.58 8.26 1.64a21.28 21.28 0 0 0 7-1.09c1.82-.64 2.91-1.46 2.91-2.19V4.05a.28.28 0 0 0 0-.09.08.08 0 0 1 .01.04ZM4.37 3.31c.73-1 3.78-1.71 7.43-1.71s6.78.75 7.46 1.74a.72.72 0 0 1 .14.41s0 .38 0 .41a2.55 2.55 0 0 0-.4-.32c-1.07-.76-3.9-1.2-7.18-1.2s-6 .4-7.1 1.14a1.73 1.73 0 0 0-.49.38v-.41a.77.77 0 0 1 .14-.44Zm15.52 6.46v2.55c0 .73-1.08 1.55-2.91 2.2a21 21 0 0 1-7 1.08c-5.67-.06-8.3-1.6-8.3-1.6C.61 13.42 0 12.82 0 12.27v-2.5a.28.28 0 0 1 0-.09.76.76 0 0 1 0-.16C0 9 .57 8.38 1.55 7.9V8c0 .58.67 1.21 1.85 1.78 0 0 2.58 1.38 8.1 1.55a19.56 19.56 0 0 0 7-1.08 10.48 10.48 0 0 0 1.32-.56 1 1 0 0 1-.08.28 1.59 1.59 0 0 0 .12-.27ZM22 15.41v2.5c0 .55-.61 1.15-1.68 1.7 0 0-2.63 1.57-8.27 1.63a21 21 0 0 1-7-1.08C3.19 19.52 2.11 18.7 2.11 18v-2.68a1.89 1.89 0 0 0 .09.21 10.35 10.35 0 0 0 1.23.52 19.78 19.78 0 0 0 7 1.08c5.52-.18 8.1-1.55 8.1-1.55 1.17-.57 1.85-1.2 1.85-1.78v-.08c1 .48 1.54 1 1.54 1.65v-.06a.28.28 0 0 1 .08.1Z"></path>
            </svg>
            <span className="unboxing-case-display-price-balance">
              {caseInfo.cost?.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </span>
          </div>
          <div className="unboxing-case-display-price hover">
            <div className="unboxing-case-display-opencase">Open Case</div>
          </div>
        </div>
      </div>
    </Link>
  );
};

const Body = () => {
  const [cases, setCases] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    axios
      .get("/cases")
      .then((response) => {
        setCases(
          response.data.filter((caseItem) => {
            return (
              caseItem.levelRequired === 1 &&
              caseItem.tags.includes("csgoempire")
            );
          })
        );
        console.log(response.data);
      })
      .catch((error) => {
        console.error("Failed to fetch cases:", error);
      });
  }, []);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredCases = cases
    .filter((caseItem) => {
      return caseItem.name.toLowerCase().includes(searchTerm.toLowerCase());
    })
    .sort(function (a, b) {
      return b.cost - a.cost;
    });

  return (
    <div className="empire-body-container">
      <div className="empire-unboxing-container">
        <div className="empire-unboxing-title">Cases</div>
        <div className="empire-unboxing-search-container">
          <div className="empire-unboxing-search-inner">
            <svg
              data-v-36d539dd=""
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              fill="currentColor"
              class="fill-current"
            >
              <g clip-path="url(#icon-search_svg__a)">
                <path d="M5.196 3.301a3.5 3.5 0 0 0-2.161 3.234.8.8 0 1 0 1.6 0c0-1.05.85-1.9 1.9-1.9a.8.8 0 1 0 0-1.6 3.5 3.5 0 0 0-1.34.266Z"></path>
                <path
                  fillRule="evenodd"
                  d="M11.663 10.53a6.5 6.5 0 1 0-1.131 1.132l2.072 2.072a.8.8 0 0 0 1.131-1.131l-2.072-2.073Zm-5.128.905a4.9 4.9 0 1 1 0-9.8 4.9 4.9 0 0 1 0 9.8Z"
                  clipRule="evenodd"
                ></path>
              </g>
              <defs>
                <clipPath id="icon-search_svg__a">
                  <path d="M0 0h14v14H0z"></path>
                </clipPath>
              </defs>
            </svg>
            <input
              value={searchTerm}
              onChange={handleSearch}
              placeholder="Search..."
            ></input>
          </div>
        </div>
        <div className="empire-unboxing-filters"></div>
        <div className="empire-unboxing-cases-container">
          <div className="empire-unboxing-cases-grid">
            {filteredCases.map((caseItem, index) => (
              <UnboxingDisplay caseInfo={caseItem} key={caseItem.id} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export const EmpireUnboxing = () => {
  return <Body />;
};
